import { BrowserRouter } from "react-router-dom";
import GlobalContextProvider from "./contexts/GlobalContext";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { createRoot } from "react-dom/client";
import AppRoutes from "./navigation/Routes";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <GlobalContextProvider>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </LocalizationProvider>
  </GlobalContextProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
