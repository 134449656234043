import { PaletteMode, ThemeOptions } from "@mui/material/styles";
import type {} from "@mui/material/themeCssVarsAugmentation";
import { componentsCustomizations } from "./customizations/components";
import { getDesignTokens } from "./themePrimitives";

export default function getMPTheme(mode: PaletteMode): ThemeOptions {
  return {
    ...getDesignTokens(mode),
    components: componentsCustomizations,
  };
}
