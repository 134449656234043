import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import App from "../App";
import PageNotFound from "../pages/PageNotFound";
import Loadable from "./Loadable";

const ContractListPage = Loadable(lazy(() => import("../pages/ContractList")));
const OpportunitiesListPage = Loadable(
  lazy(() => import("../pages/OpportunitiesList")),
);
const TrackerListPage = Loadable(lazy(() => import("../pages/TrackerList")));
const TrackerDetailPage = Loadable(
  lazy(() => import("../pages/TrackerDetail")),
);
const ContactsListPage = Loadable(lazy(() => import("../pages/ContactsList")));
const VitacurryHomePage = Loadable(
  lazy(() => import("../pages/VitacurryHome")),
);

const SettingsPage = Loadable(lazy(() => import("../pages/SettingsPage")));

const getMainRoutes = (): RouteObject => {
  return {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Navigate to="/contracts" replace />,
      },
      {
        path: "/contracts",
        children: [
          {
            path: "",
            element: <ContractListPage />,
          },
          {
            path: ":contractId",
            element: <TrackerListPage />,
          },
          {
            path: ":contractId/trackers/:trackerYear/:trackerMonth",
            element: <TrackerDetailPage />,
          },
        ],
      },
      {
        path: "/contacts",
        element: <ContactsListPage />,
      },

      {
        path: "/opportunities",
        element: <OpportunitiesListPage />,
      },
      {
        path: "/vitacurry",
        element: <VitacurryHomePage />,
      },
      {
        path: "/settings",
        element: <SettingsPage />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  };
};

export default getMainRoutes;
