import LinkOffIcon from "@mui/icons-material/LinkOff";
import { Grid2 as Grid, Stack, Typography } from "@mui/material";
import { PageContainer } from "@toolpad/core";
import { useTranslation } from "react-i18next";

export default function PageNotFound() {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "50vh" }}
      >
        <Grid>
          <Stack spacing={2} direction={"column"} alignItems={"center"}>
            <LinkOffIcon
              sx={{
                fontSize: "10rem",
              }}
              color="warning"
            />
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              404 {t("Page Not Found")}
            </Typography>
            <Typography variant="body1">
              {t(
                "The page you are looking for does not exist or has been moved.",
              )}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </PageContainer>
  );
}
