// @ts-nocheck
/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Lancer Express API
 * API Docs
 * OpenAPI spec version: 1.0.0
 */
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSwr from "swr";
import type { SWRMutationConfiguration } from "swr/mutation";
import useSWRMutation from "swr/mutation";
import type {
  Contact,
  Contract,
  Education,
  ExternalProfile,
  Jwt,
  Login,
  Opportunity,
  PaginatedAvailableDatesList,
  PaginatedContactList,
  PaginatedContractList,
  PaginatedEducationList,
  PaginatedExternalProfileList,
  PaginatedOpportunityList,
  PaginatedPortfolioItemList,
  PaginatedProfileList,
  PaginatedSkillList,
  PaginatedTimesheetEntryList,
  PaginatedTimesheetList,
  PaginatedTrackerList,
  PaginatedUserList,
  PasswordChange,
  PasswordReset,
  PasswordResetConfirm,
  PatchedContact,
  PatchedContract,
  PatchedEducation,
  PatchedExternalProfile,
  PatchedOpportunity,
  PatchedPortfolioItem,
  PatchedProfile,
  PatchedSkill,
  PatchedTimesheet,
  PatchedTimesheetApproval,
  PatchedTimesheetEntry,
  PatchedTracker,
  PatchedUser,
  PatchedUserDetails,
  PortfolioItem,
  Profile,
  Register,
  ResendEmailVerification,
  RestAuthDetail,
  Skill,
  SubscriptionListResponse,
  Timesheet,
  TimesheetApproval,
  TimesheetEntry,
  TokenRefresh,
  TokenVerify,
  Tracker,
  User,
  UserDetails,
  UserMe,
  V0ContactsListParams,
  V0ContractsAvailableDatesListParams,
  V0ContractsListParams,
  V0OpportunitiesListParams,
  V0TimesheetEntriesListParams,
  V0TimesheetsListParams,
  V0TrackersListParams,
  V0UsersListParams,
  V0VitacurryEducationsListParams,
  V0VitacurryExternalProfilesListParams,
  V0VitacurryPortfolioListParams,
  V0VitacurryProfilesListParams,
  V0VitacurrySkillsListParams,
  VerifyEmail,
} from "./lancerExpressAPI.schemas";

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> =
  (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<
    { [Q in P]: T[P] },
    { -readonly [Q in P]: T[P] },
    P
  >;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

/**
 * Check the credentials and return the REST Token
if the credentials are valid and authenticated.
Calls Django Auth login method to register User ID
in Django session framework

Accept the following POST parameters: username, password
Return the REST Framework Token Object's key.
 */
export const authLoginCreate = (
  login: Login,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Jwt>> => {
  return axios.post(`/de/api/auth/login/`, login, options);
};

export const getAuthLoginCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (_: Key, { arg }: { arg: Login }): Promise<AxiosResponse<Jwt>> => {
    return authLoginCreate(arg, options);
  };
};
export const getAuthLoginCreateMutationKey = () =>
  [`/de/api/auth/login/`] as const;

export type AuthLoginCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authLoginCreate>>
>;
export type AuthLoginCreateMutationError = AxiosError<unknown>;

export const useAuthLoginCreate = <TError = AxiosError<unknown>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authLoginCreate>>,
    TError,
    Key,
    Login,
    Awaited<ReturnType<typeof authLoginCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAuthLoginCreateMutationKey();
  const swrFn = getAuthLoginCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

/**
 * Calls Django logout method and delete the Token object
assigned to the current User object.

Accepts/Returns nothing.
 */
export const authLogoutCreate = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<RestAuthDetail>> => {
  return axios.post(`/de/api/auth/logout/`, undefined, options);
};

export const getAuthLogoutCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    __: { arg: Arguments },
  ): Promise<AxiosResponse<RestAuthDetail>> => {
    return authLogoutCreate(options);
  };
};
export const getAuthLogoutCreateMutationKey = () =>
  [`/de/api/auth/logout/`] as const;

export type AuthLogoutCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authLogoutCreate>>
>;
export type AuthLogoutCreateMutationError = AxiosError<unknown>;

export const useAuthLogoutCreate = <TError = AxiosError<unknown>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authLogoutCreate>>,
    TError,
    Key,
    Arguments,
    Awaited<ReturnType<typeof authLogoutCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAuthLogoutCreateMutationKey();
  const swrFn = getAuthLogoutCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

/**
 * Calls Django Auth SetPasswordForm save method.

Accepts the following POST parameters: new_password1, new_password2
Returns the success/fail message.
 */
export const authPasswordChangeCreate = (
  passwordChange: PasswordChange,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<RestAuthDetail>> => {
  return axios.post(`/de/api/auth/password/change/`, passwordChange, options);
};

export const getAuthPasswordChangeCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: PasswordChange },
  ): Promise<AxiosResponse<RestAuthDetail>> => {
    return authPasswordChangeCreate(arg, options);
  };
};
export const getAuthPasswordChangeCreateMutationKey = () =>
  [`/de/api/auth/password/change/`] as const;

export type AuthPasswordChangeCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authPasswordChangeCreate>>
>;
export type AuthPasswordChangeCreateMutationError = AxiosError<unknown>;

export const useAuthPasswordChangeCreate = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authPasswordChangeCreate>>,
    TError,
    Key,
    PasswordChange,
    Awaited<ReturnType<typeof authPasswordChangeCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAuthPasswordChangeCreateMutationKey();
  const swrFn = getAuthPasswordChangeCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

/**
 * Calls Django Auth PasswordResetForm save method.

Accepts the following POST parameters: email
Returns the success/fail message.
 */
export const authPasswordResetCreate = (
  passwordReset: PasswordReset,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<RestAuthDetail>> => {
  return axios.post(`/de/api/auth/password/reset/`, passwordReset, options);
};

export const getAuthPasswordResetCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: PasswordReset },
  ): Promise<AxiosResponse<RestAuthDetail>> => {
    return authPasswordResetCreate(arg, options);
  };
};
export const getAuthPasswordResetCreateMutationKey = () =>
  [`/de/api/auth/password/reset/`] as const;

export type AuthPasswordResetCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authPasswordResetCreate>>
>;
export type AuthPasswordResetCreateMutationError = AxiosError<unknown>;

export const useAuthPasswordResetCreate = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authPasswordResetCreate>>,
    TError,
    Key,
    PasswordReset,
    Awaited<ReturnType<typeof authPasswordResetCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAuthPasswordResetCreateMutationKey();
  const swrFn = getAuthPasswordResetCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

/**
 * Password reset e-mail link is confirmed, therefore
this resets the user's password.

Accepts the following POST parameters: token, uid,
    new_password1, new_password2
Returns the success/fail message.
 */
export const authPasswordResetConfirmCreate = (
  passwordResetConfirm: PasswordResetConfirm,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<RestAuthDetail>> => {
  return axios.post(
    `/de/api/auth/password/reset/confirm/`,
    passwordResetConfirm,
    options,
  );
};

export const getAuthPasswordResetConfirmCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: PasswordResetConfirm },
  ): Promise<AxiosResponse<RestAuthDetail>> => {
    return authPasswordResetConfirmCreate(arg, options);
  };
};
export const getAuthPasswordResetConfirmCreateMutationKey = () =>
  [`/de/api/auth/password/reset/confirm/`] as const;

export type AuthPasswordResetConfirmCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authPasswordResetConfirmCreate>>
>;
export type AuthPasswordResetConfirmCreateMutationError = AxiosError<unknown>;

export const useAuthPasswordResetConfirmCreate = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authPasswordResetConfirmCreate>>,
    TError,
    Key,
    PasswordResetConfirm,
    Awaited<ReturnType<typeof authPasswordResetConfirmCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getAuthPasswordResetConfirmCreateMutationKey();
  const swrFn = getAuthPasswordResetConfirmCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const authRegisterCreate = (
  register: Register,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Jwt>> => {
  return axios.post(`/de/api/auth/register/`, register, options);
};

export const getAuthRegisterCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (_: Key, { arg }: { arg: Register }): Promise<AxiosResponse<Jwt>> => {
    return authRegisterCreate(arg, options);
  };
};
export const getAuthRegisterCreateMutationKey = () =>
  [`/de/api/auth/register/`] as const;

export type AuthRegisterCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authRegisterCreate>>
>;
export type AuthRegisterCreateMutationError = AxiosError<unknown>;

export const useAuthRegisterCreate = <TError = AxiosError<unknown>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authRegisterCreate>>,
    TError,
    Key,
    Register,
    Awaited<ReturnType<typeof authRegisterCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAuthRegisterCreateMutationKey();
  const swrFn = getAuthRegisterCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const authRegisterResendEmailCreate = (
  resendEmailVerification: ResendEmailVerification,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<RestAuthDetail>> => {
  return axios.post(
    `/de/api/auth/register/resend-email/`,
    resendEmailVerification,
    options,
  );
};

export const getAuthRegisterResendEmailCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: ResendEmailVerification },
  ): Promise<AxiosResponse<RestAuthDetail>> => {
    return authRegisterResendEmailCreate(arg, options);
  };
};
export const getAuthRegisterResendEmailCreateMutationKey = () =>
  [`/de/api/auth/register/resend-email/`] as const;

export type AuthRegisterResendEmailCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authRegisterResendEmailCreate>>
>;
export type AuthRegisterResendEmailCreateMutationError = AxiosError<unknown>;

export const useAuthRegisterResendEmailCreate = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authRegisterResendEmailCreate>>,
    TError,
    Key,
    ResendEmailVerification,
    Awaited<ReturnType<typeof authRegisterResendEmailCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getAuthRegisterResendEmailCreateMutationKey();
  const swrFn = getAuthRegisterResendEmailCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const authRegisterVerifyEmailCreate = (
  verifyEmail: VerifyEmail,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<RestAuthDetail>> => {
  return axios.post(
    `/de/api/auth/register/verify-email/`,
    verifyEmail,
    options,
  );
};

export const getAuthRegisterVerifyEmailCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: VerifyEmail },
  ): Promise<AxiosResponse<RestAuthDetail>> => {
    return authRegisterVerifyEmailCreate(arg, options);
  };
};
export const getAuthRegisterVerifyEmailCreateMutationKey = () =>
  [`/de/api/auth/register/verify-email/`] as const;

export type AuthRegisterVerifyEmailCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authRegisterVerifyEmailCreate>>
>;
export type AuthRegisterVerifyEmailCreateMutationError = AxiosError<unknown>;

export const useAuthRegisterVerifyEmailCreate = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authRegisterVerifyEmailCreate>>,
    TError,
    Key,
    VerifyEmail,
    Awaited<ReturnType<typeof authRegisterVerifyEmailCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getAuthRegisterVerifyEmailCreateMutationKey();
  const swrFn = getAuthRegisterVerifyEmailCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

/**
 * Takes a refresh type JSON web token and returns an access type JSON web
token if the refresh token is valid.
 */
export const authTokenRefreshCreate = (
  tokenRefresh: NonReadonly<TokenRefresh>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<TokenRefresh>> => {
  return axios.post(`/de/api/auth/token/refresh/`, tokenRefresh, options);
};

export const getAuthTokenRefreshCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<TokenRefresh> },
  ): Promise<AxiosResponse<TokenRefresh>> => {
    return authTokenRefreshCreate(arg, options);
  };
};
export const getAuthTokenRefreshCreateMutationKey = () =>
  [`/de/api/auth/token/refresh/`] as const;

export type AuthTokenRefreshCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authTokenRefreshCreate>>
>;
export type AuthTokenRefreshCreateMutationError = AxiosError<unknown>;

export const useAuthTokenRefreshCreate = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authTokenRefreshCreate>>,
    TError,
    Key,
    NonReadonly<TokenRefresh>,
    Awaited<ReturnType<typeof authTokenRefreshCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAuthTokenRefreshCreateMutationKey();
  const swrFn = getAuthTokenRefreshCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

/**
 * Takes a token and indicates if it is valid.  This view provides no
information about a token's fitness for a particular use.
 */
export const authTokenVerifyCreate = (
  tokenVerify: TokenVerify,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<TokenVerify>> => {
  return axios.post(`/de/api/auth/token/verify/`, tokenVerify, options);
};

export const getAuthTokenVerifyCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: TokenVerify },
  ): Promise<AxiosResponse<TokenVerify>> => {
    return authTokenVerifyCreate(arg, options);
  };
};
export const getAuthTokenVerifyCreateMutationKey = () =>
  [`/de/api/auth/token/verify/`] as const;

export type AuthTokenVerifyCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authTokenVerifyCreate>>
>;
export type AuthTokenVerifyCreateMutationError = AxiosError<unknown>;

export const useAuthTokenVerifyCreate = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authTokenVerifyCreate>>,
    TError,
    Key,
    TokenVerify,
    Awaited<ReturnType<typeof authTokenVerifyCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAuthTokenVerifyCreateMutationKey();
  const swrFn = getAuthTokenVerifyCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

/**
 * Reads and updates UserModel fields
Accepts GET, PUT, PATCH methods.

Default accepted fields: username, first_name, last_name
Default display fields: pk, username, email, first_name, last_name
Read-only fields: pk, email

Returns UserModel fields.
 */
export const authUserRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<UserDetails>> => {
  return axios.get(`/de/api/auth/user/`, options);
};

export const getAuthUserRetrieveKey = () => [`/de/api/auth/user/`] as const;

export type AuthUserRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof authUserRetrieve>>
>;
export type AuthUserRetrieveQueryError = AxiosError<unknown>;

export const useAuthUserRetrieve = <TError = AxiosError<unknown>>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof authUserRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getAuthUserRetrieveKey() : null));
  const swrFn = () => authUserRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Reads and updates UserModel fields
Accepts GET, PUT, PATCH methods.

Default accepted fields: username, first_name, last_name
Default display fields: pk, username, email, first_name, last_name
Read-only fields: pk, email

Returns UserModel fields.
 */
export const authUserUpdate = (
  userDetails: NonReadonly<UserDetails>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<UserDetails>> => {
  return axios.put(`/de/api/auth/user/`, userDetails, options);
};

export const getAuthUserUpdateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<UserDetails> },
  ): Promise<AxiosResponse<UserDetails>> => {
    return authUserUpdate(arg, options);
  };
};
export const getAuthUserUpdateMutationKey = () =>
  [`/de/api/auth/user/`] as const;

export type AuthUserUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authUserUpdate>>
>;
export type AuthUserUpdateMutationError = AxiosError<unknown>;

export const useAuthUserUpdate = <TError = AxiosError<unknown>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authUserUpdate>>,
    TError,
    Key,
    NonReadonly<UserDetails>,
    Awaited<ReturnType<typeof authUserUpdate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAuthUserUpdateMutationKey();
  const swrFn = getAuthUserUpdateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

/**
 * Reads and updates UserModel fields
Accepts GET, PUT, PATCH methods.

Default accepted fields: username, first_name, last_name
Default display fields: pk, username, email, first_name, last_name
Read-only fields: pk, email

Returns UserModel fields.
 */
export const authUserPartialUpdate = (
  patchedUserDetails: NonReadonly<PatchedUserDetails>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<UserDetails>> => {
  return axios.patch(`/de/api/auth/user/`, patchedUserDetails, options);
};

export const getAuthUserPartialUpdateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PatchedUserDetails> },
  ): Promise<AxiosResponse<UserDetails>> => {
    return authUserPartialUpdate(arg, options);
  };
};
export const getAuthUserPartialUpdateMutationKey = () =>
  [`/de/api/auth/user/`] as const;

export type AuthUserPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof authUserPartialUpdate>>
>;
export type AuthUserPartialUpdateMutationError = AxiosError<unknown>;

export const useAuthUserPartialUpdate = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authUserPartialUpdate>>,
    TError,
    Key,
    NonReadonly<PatchedUserDetails>,
    Awaited<ReturnType<typeof authUserPartialUpdate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAuthUserPartialUpdateMutationKey();
  const swrFn = getAuthUserPartialUpdateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0ContactsList = (
  params?: V0ContactsListParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PaginatedContactList>> => {
  return axios.get(`/de/api/v0/contacts/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getV0ContactsListKey = (params?: V0ContactsListParams) =>
  [`/de/api/v0/contacts/`, ...(params ? [params] : [])] as const;

export type V0ContactsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0ContactsList>>
>;
export type V0ContactsListQueryError = AxiosError<unknown>;

export const useV0ContactsList = <TError = AxiosError<unknown>>(
  params?: V0ContactsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0ContactsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0ContactsListKey(params) : null));
  const swrFn = () => v0ContactsList(params, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0ContactsCreate = (
  contact: NonReadonly<Contact>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Contact>> => {
  return axios.post(`/de/api/v0/contacts/`, contact, options);
};

export const getV0ContactsCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Contact> },
  ): Promise<AxiosResponse<Contact>> => {
    return v0ContactsCreate(arg, options);
  };
};
export const getV0ContactsCreateMutationKey = () =>
  [`/de/api/v0/contacts/`] as const;

export type V0ContactsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0ContactsCreate>>
>;
export type V0ContactsCreateMutationError = AxiosError<unknown>;

export const useV0ContactsCreate = <TError = AxiosError<unknown>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof v0ContactsCreate>>,
    TError,
    Key,
    NonReadonly<Contact>,
    Awaited<ReturnType<typeof v0ContactsCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0ContactsCreateMutationKey();
  const swrFn = getV0ContactsCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0ContactsRetrieve = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Contact>> => {
  return axios.get(`/de/api/v0/contacts/${id}/`, options);
};

export const getV0ContactsRetrieveKey = (id: string) =>
  [`/de/api/v0/contacts/${id}/`] as const;

export type V0ContactsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0ContactsRetrieve>>
>;
export type V0ContactsRetrieveQueryError = AxiosError<unknown>;

export const useV0ContactsRetrieve = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0ContactsRetrieve>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0ContactsRetrieveKey(id) : null));
  const swrFn = () => v0ContactsRetrieve(id, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0ContactsUpdate = (
  id: string,
  contact: NonReadonly<Contact>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Contact>> => {
  return axios.put(`/de/api/v0/contacts/${id}/`, contact, options);
};

export const getV0ContactsUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Contact> },
  ): Promise<AxiosResponse<Contact>> => {
    return v0ContactsUpdate(id, arg, options);
  };
};
export const getV0ContactsUpdateMutationKey = (id: string) =>
  [`/de/api/v0/contacts/${id}/`] as const;

export type V0ContactsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0ContactsUpdate>>
>;
export type V0ContactsUpdateMutationError = AxiosError<unknown>;

export const useV0ContactsUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0ContactsUpdate>>,
      TError,
      Key,
      NonReadonly<Contact>,
      Awaited<ReturnType<typeof v0ContactsUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0ContactsUpdateMutationKey(id);
  const swrFn = getV0ContactsUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0ContactsPartialUpdate = (
  id: string,
  patchedContact: NonReadonly<PatchedContact>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Contact>> => {
  return axios.patch(`/de/api/v0/contacts/${id}/`, patchedContact, options);
};

export const getV0ContactsPartialUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PatchedContact> },
  ): Promise<AxiosResponse<Contact>> => {
    return v0ContactsPartialUpdate(id, arg, options);
  };
};
export const getV0ContactsPartialUpdateMutationKey = (id: string) =>
  [`/de/api/v0/contacts/${id}/`] as const;

export type V0ContactsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0ContactsPartialUpdate>>
>;
export type V0ContactsPartialUpdateMutationError = AxiosError<unknown>;

export const useV0ContactsPartialUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0ContactsPartialUpdate>>,
      TError,
      Key,
      NonReadonly<PatchedContact>,
      Awaited<ReturnType<typeof v0ContactsPartialUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0ContactsPartialUpdateMutationKey(id);
  const swrFn = getV0ContactsPartialUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0ContactsDestroy = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/de/api/v0/contacts/${id}/`, options);
};

export const getV0ContactsDestroyMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<AxiosResponse<void>> => {
    return v0ContactsDestroy(id, options);
  };
};
export const getV0ContactsDestroyMutationKey = (id: string) =>
  [`/de/api/v0/contacts/${id}/`] as const;

export type V0ContactsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0ContactsDestroy>>
>;
export type V0ContactsDestroyMutationError = AxiosError<unknown>;

export const useV0ContactsDestroy = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0ContactsDestroy>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof v0ContactsDestroy>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0ContactsDestroyMutationKey(id);
  const swrFn = getV0ContactsDestroyMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0ContactsFormSchemaRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Contact>> => {
  return axios.get(`/de/api/v0/contacts/form_schema/`, options);
};

export const getV0ContactsFormSchemaRetrieveKey = () =>
  [`/de/api/v0/contacts/form_schema/`] as const;

export type V0ContactsFormSchemaRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0ContactsFormSchemaRetrieve>>
>;
export type V0ContactsFormSchemaRetrieveQueryError = AxiosError<unknown>;

export const useV0ContactsFormSchemaRetrieve = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0ContactsFormSchemaRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0ContactsFormSchemaRetrieveKey() : null));
  const swrFn = () => v0ContactsFormSchemaRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0ContractsList = (
  params?: V0ContractsListParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PaginatedContractList>> => {
  return axios.get(`/de/api/v0/contracts/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getV0ContractsListKey = (params?: V0ContractsListParams) =>
  [`/de/api/v0/contracts/`, ...(params ? [params] : [])] as const;

export type V0ContractsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0ContractsList>>
>;
export type V0ContractsListQueryError = AxiosError<unknown>;

export const useV0ContractsList = <TError = AxiosError<unknown>>(
  params?: V0ContractsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0ContractsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0ContractsListKey(params) : null));
  const swrFn = () => v0ContractsList(params, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0ContractsCreate = (
  contract: NonReadonly<Contract>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Contract>> => {
  return axios.post(`/de/api/v0/contracts/`, contract, options);
};

export const getV0ContractsCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Contract> },
  ): Promise<AxiosResponse<Contract>> => {
    return v0ContractsCreate(arg, options);
  };
};
export const getV0ContractsCreateMutationKey = () =>
  [`/de/api/v0/contracts/`] as const;

export type V0ContractsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0ContractsCreate>>
>;
export type V0ContractsCreateMutationError = AxiosError<unknown>;

export const useV0ContractsCreate = <TError = AxiosError<unknown>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof v0ContractsCreate>>,
    TError,
    Key,
    NonReadonly<Contract>,
    Awaited<ReturnType<typeof v0ContractsCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0ContractsCreateMutationKey();
  const swrFn = getV0ContractsCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0ContractsRetrieve = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Contract>> => {
  return axios.get(`/de/api/v0/contracts/${id}/`, options);
};

export const getV0ContractsRetrieveKey = (id: string) =>
  [`/de/api/v0/contracts/${id}/`] as const;

export type V0ContractsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0ContractsRetrieve>>
>;
export type V0ContractsRetrieveQueryError = AxiosError<unknown>;

export const useV0ContractsRetrieve = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0ContractsRetrieve>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0ContractsRetrieveKey(id) : null));
  const swrFn = () => v0ContractsRetrieve(id, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0ContractsUpdate = (
  id: string,
  contract: NonReadonly<Contract>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Contract>> => {
  return axios.put(`/de/api/v0/contracts/${id}/`, contract, options);
};

export const getV0ContractsUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Contract> },
  ): Promise<AxiosResponse<Contract>> => {
    return v0ContractsUpdate(id, arg, options);
  };
};
export const getV0ContractsUpdateMutationKey = (id: string) =>
  [`/de/api/v0/contracts/${id}/`] as const;

export type V0ContractsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0ContractsUpdate>>
>;
export type V0ContractsUpdateMutationError = AxiosError<unknown>;

export const useV0ContractsUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0ContractsUpdate>>,
      TError,
      Key,
      NonReadonly<Contract>,
      Awaited<ReturnType<typeof v0ContractsUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0ContractsUpdateMutationKey(id);
  const swrFn = getV0ContractsUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0ContractsPartialUpdate = (
  id: string,
  patchedContract: NonReadonly<PatchedContract>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Contract>> => {
  return axios.patch(`/de/api/v0/contracts/${id}/`, patchedContract, options);
};

export const getV0ContractsPartialUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PatchedContract> },
  ): Promise<AxiosResponse<Contract>> => {
    return v0ContractsPartialUpdate(id, arg, options);
  };
};
export const getV0ContractsPartialUpdateMutationKey = (id: string) =>
  [`/de/api/v0/contracts/${id}/`] as const;

export type V0ContractsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0ContractsPartialUpdate>>
>;
export type V0ContractsPartialUpdateMutationError = AxiosError<unknown>;

export const useV0ContractsPartialUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0ContractsPartialUpdate>>,
      TError,
      Key,
      NonReadonly<PatchedContract>,
      Awaited<ReturnType<typeof v0ContractsPartialUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0ContractsPartialUpdateMutationKey(id);
  const swrFn = getV0ContractsPartialUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0ContractsDestroy = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/de/api/v0/contracts/${id}/`, options);
};

export const getV0ContractsDestroyMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<AxiosResponse<void>> => {
    return v0ContractsDestroy(id, options);
  };
};
export const getV0ContractsDestroyMutationKey = (id: string) =>
  [`/de/api/v0/contracts/${id}/`] as const;

export type V0ContractsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0ContractsDestroy>>
>;
export type V0ContractsDestroyMutationError = AxiosError<unknown>;

export const useV0ContractsDestroy = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0ContractsDestroy>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof v0ContractsDestroy>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0ContractsDestroyMutationKey(id);
  const swrFn = getV0ContractsDestroyMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0ContractsAvailableDatesList = (
  id: string,
  params?: V0ContractsAvailableDatesListParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PaginatedAvailableDatesList>> => {
  return axios.get(`/de/api/v0/contracts/${id}/available_dates/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getV0ContractsAvailableDatesListKey = (
  id: string,
  params?: V0ContractsAvailableDatesListParams,
) =>
  [
    `/de/api/v0/contracts/${id}/available_dates/`,
    ...(params ? [params] : []),
  ] as const;

export type V0ContractsAvailableDatesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0ContractsAvailableDatesList>>
>;
export type V0ContractsAvailableDatesListQueryError = AxiosError<unknown>;

export const useV0ContractsAvailableDatesList = <TError = AxiosError<unknown>>(
  id: string,
  params?: V0ContractsAvailableDatesListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0ContractsAvailableDatesList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getV0ContractsAvailableDatesListKey(id, params) : null);
  const swrFn = () => v0ContractsAvailableDatesList(id, params, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0ContractsFormSchemaRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Contract>> => {
  return axios.get(`/de/api/v0/contracts/form_schema/`, options);
};

export const getV0ContractsFormSchemaRetrieveKey = () =>
  [`/de/api/v0/contracts/form_schema/`] as const;

export type V0ContractsFormSchemaRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0ContractsFormSchemaRetrieve>>
>;
export type V0ContractsFormSchemaRetrieveQueryError = AxiosError<unknown>;

export const useV0ContractsFormSchemaRetrieve = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0ContractsFormSchemaRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0ContractsFormSchemaRetrieveKey() : null));
  const swrFn = () => v0ContractsFormSchemaRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0OpportunitiesList = (
  params?: V0OpportunitiesListParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PaginatedOpportunityList>> => {
  return axios.get(`/de/api/v0/opportunities/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getV0OpportunitiesListKey = (params?: V0OpportunitiesListParams) =>
  [`/de/api/v0/opportunities/`, ...(params ? [params] : [])] as const;

export type V0OpportunitiesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0OpportunitiesList>>
>;
export type V0OpportunitiesListQueryError = AxiosError<unknown>;

export const useV0OpportunitiesList = <TError = AxiosError<unknown>>(
  params?: V0OpportunitiesListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0OpportunitiesList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0OpportunitiesListKey(params) : null));
  const swrFn = () => v0OpportunitiesList(params, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0OpportunitiesCreate = (
  opportunity: NonReadonly<Opportunity>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Opportunity>> => {
  return axios.post(`/de/api/v0/opportunities/`, opportunity, options);
};

export const getV0OpportunitiesCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Opportunity> },
  ): Promise<AxiosResponse<Opportunity>> => {
    return v0OpportunitiesCreate(arg, options);
  };
};
export const getV0OpportunitiesCreateMutationKey = () =>
  [`/de/api/v0/opportunities/`] as const;

export type V0OpportunitiesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0OpportunitiesCreate>>
>;
export type V0OpportunitiesCreateMutationError = AxiosError<unknown>;

export const useV0OpportunitiesCreate = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof v0OpportunitiesCreate>>,
    TError,
    Key,
    NonReadonly<Opportunity>,
    Awaited<ReturnType<typeof v0OpportunitiesCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0OpportunitiesCreateMutationKey();
  const swrFn = getV0OpportunitiesCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0OpportunitiesRetrieve = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Opportunity>> => {
  return axios.get(`/de/api/v0/opportunities/${id}/`, options);
};

export const getV0OpportunitiesRetrieveKey = (id: string) =>
  [`/de/api/v0/opportunities/${id}/`] as const;

export type V0OpportunitiesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0OpportunitiesRetrieve>>
>;
export type V0OpportunitiesRetrieveQueryError = AxiosError<unknown>;

export const useV0OpportunitiesRetrieve = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0OpportunitiesRetrieve>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0OpportunitiesRetrieveKey(id) : null));
  const swrFn = () => v0OpportunitiesRetrieve(id, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0OpportunitiesUpdate = (
  id: string,
  opportunity: NonReadonly<Opportunity>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Opportunity>> => {
  return axios.put(`/de/api/v0/opportunities/${id}/`, opportunity, options);
};

export const getV0OpportunitiesUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Opportunity> },
  ): Promise<AxiosResponse<Opportunity>> => {
    return v0OpportunitiesUpdate(id, arg, options);
  };
};
export const getV0OpportunitiesUpdateMutationKey = (id: string) =>
  [`/de/api/v0/opportunities/${id}/`] as const;

export type V0OpportunitiesUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0OpportunitiesUpdate>>
>;
export type V0OpportunitiesUpdateMutationError = AxiosError<unknown>;

export const useV0OpportunitiesUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0OpportunitiesUpdate>>,
      TError,
      Key,
      NonReadonly<Opportunity>,
      Awaited<ReturnType<typeof v0OpportunitiesUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0OpportunitiesUpdateMutationKey(id);
  const swrFn = getV0OpportunitiesUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0OpportunitiesPartialUpdate = (
  id: string,
  patchedOpportunity: NonReadonly<PatchedOpportunity>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Opportunity>> => {
  return axios.patch(
    `/de/api/v0/opportunities/${id}/`,
    patchedOpportunity,
    options,
  );
};

export const getV0OpportunitiesPartialUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PatchedOpportunity> },
  ): Promise<AxiosResponse<Opportunity>> => {
    return v0OpportunitiesPartialUpdate(id, arg, options);
  };
};
export const getV0OpportunitiesPartialUpdateMutationKey = (id: string) =>
  [`/de/api/v0/opportunities/${id}/`] as const;

export type V0OpportunitiesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0OpportunitiesPartialUpdate>>
>;
export type V0OpportunitiesPartialUpdateMutationError = AxiosError<unknown>;

export const useV0OpportunitiesPartialUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0OpportunitiesPartialUpdate>>,
      TError,
      Key,
      NonReadonly<PatchedOpportunity>,
      Awaited<ReturnType<typeof v0OpportunitiesPartialUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0OpportunitiesPartialUpdateMutationKey(id);
  const swrFn = getV0OpportunitiesPartialUpdateMutationFetcher(
    id,
    axiosOptions,
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0OpportunitiesDestroy = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/de/api/v0/opportunities/${id}/`, options);
};

export const getV0OpportunitiesDestroyMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<AxiosResponse<void>> => {
    return v0OpportunitiesDestroy(id, options);
  };
};
export const getV0OpportunitiesDestroyMutationKey = (id: string) =>
  [`/de/api/v0/opportunities/${id}/`] as const;

export type V0OpportunitiesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0OpportunitiesDestroy>>
>;
export type V0OpportunitiesDestroyMutationError = AxiosError<unknown>;

export const useV0OpportunitiesDestroy = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0OpportunitiesDestroy>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof v0OpportunitiesDestroy>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0OpportunitiesDestroyMutationKey(id);
  const swrFn = getV0OpportunitiesDestroyMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0OpportunitiesFormSchemaRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Opportunity>> => {
  return axios.get(`/de/api/v0/opportunities/form_schema/`, options);
};

export const getV0OpportunitiesFormSchemaRetrieveKey = () =>
  [`/de/api/v0/opportunities/form_schema/`] as const;

export type V0OpportunitiesFormSchemaRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0OpportunitiesFormSchemaRetrieve>>
>;
export type V0OpportunitiesFormSchemaRetrieveQueryError = AxiosError<unknown>;

export const useV0OpportunitiesFormSchemaRetrieve = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0OpportunitiesFormSchemaRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0OpportunitiesFormSchemaRetrieveKey() : null));
  const swrFn = () => v0OpportunitiesFormSchemaRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List subscriptions for the current user
 * @summary List subscriptions
 */
export const v0SubscriptionsList = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<SubscriptionListResponse[]>> => {
  return axios.get(`/de/api/v0/subscriptions/`, options);
};

export const getV0SubscriptionsListKey = () =>
  [`/de/api/v0/subscriptions/`] as const;

export type V0SubscriptionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0SubscriptionsList>>
>;
export type V0SubscriptionsListQueryError = AxiosError<unknown>;

/**
 * @summary List subscriptions
 */
export const useV0SubscriptionsList = <TError = AxiosError<unknown>>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0SubscriptionsList>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0SubscriptionsListKey() : null));
  const swrFn = () => v0SubscriptionsList(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetApprovalsRetrieve = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<TimesheetApproval>> => {
  return axios.get(`/de/api/v0/timesheet_approvals/${id}/`, options);
};

export const getV0TimesheetApprovalsRetrieveKey = (id: string) =>
  [`/de/api/v0/timesheet_approvals/${id}/`] as const;

export type V0TimesheetApprovalsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetApprovalsRetrieve>>
>;
export type V0TimesheetApprovalsRetrieveQueryError = AxiosError<unknown>;

export const useV0TimesheetApprovalsRetrieve = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0TimesheetApprovalsRetrieve>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0TimesheetApprovalsRetrieveKey(id) : null));
  const swrFn = () => v0TimesheetApprovalsRetrieve(id, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetApprovalsUpdate = (
  id: string,
  timesheetApproval: NonReadonly<TimesheetApproval>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<TimesheetApproval>> => {
  return axios.put(
    `/de/api/v0/timesheet_approvals/${id}/`,
    timesheetApproval,
    options,
  );
};

export const getV0TimesheetApprovalsUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<TimesheetApproval> },
  ): Promise<AxiosResponse<TimesheetApproval>> => {
    return v0TimesheetApprovalsUpdate(id, arg, options);
  };
};
export const getV0TimesheetApprovalsUpdateMutationKey = (id: string) =>
  [`/de/api/v0/timesheet_approvals/${id}/`] as const;

export type V0TimesheetApprovalsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetApprovalsUpdate>>
>;
export type V0TimesheetApprovalsUpdateMutationError = AxiosError<unknown>;

export const useV0TimesheetApprovalsUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0TimesheetApprovalsUpdate>>,
      TError,
      Key,
      NonReadonly<TimesheetApproval>,
      Awaited<ReturnType<typeof v0TimesheetApprovalsUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0TimesheetApprovalsUpdateMutationKey(id);
  const swrFn = getV0TimesheetApprovalsUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetApprovalsPartialUpdate = (
  id: string,
  patchedTimesheetApproval: NonReadonly<PatchedTimesheetApproval>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<TimesheetApproval>> => {
  return axios.patch(
    `/de/api/v0/timesheet_approvals/${id}/`,
    patchedTimesheetApproval,
    options,
  );
};

export const getV0TimesheetApprovalsPartialUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PatchedTimesheetApproval> },
  ): Promise<AxiosResponse<TimesheetApproval>> => {
    return v0TimesheetApprovalsPartialUpdate(id, arg, options);
  };
};
export const getV0TimesheetApprovalsPartialUpdateMutationKey = (id: string) =>
  [`/de/api/v0/timesheet_approvals/${id}/`] as const;

export type V0TimesheetApprovalsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetApprovalsPartialUpdate>>
>;
export type V0TimesheetApprovalsPartialUpdateMutationError =
  AxiosError<unknown>;

export const useV0TimesheetApprovalsPartialUpdate = <
  TError = AxiosError<unknown>,
>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0TimesheetApprovalsPartialUpdate>>,
      TError,
      Key,
      NonReadonly<PatchedTimesheetApproval>,
      Awaited<ReturnType<typeof v0TimesheetApprovalsPartialUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0TimesheetApprovalsPartialUpdateMutationKey(id);
  const swrFn = getV0TimesheetApprovalsPartialUpdateMutationFetcher(
    id,
    axiosOptions,
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetApprovalsFormSchemaRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<TimesheetApproval>> => {
  return axios.get(`/de/api/v0/timesheet_approvals/form_schema/`, options);
};

export const getV0TimesheetApprovalsFormSchemaRetrieveKey = () =>
  [`/de/api/v0/timesheet_approvals/form_schema/`] as const;

export type V0TimesheetApprovalsFormSchemaRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetApprovalsFormSchemaRetrieve>>
>;
export type V0TimesheetApprovalsFormSchemaRetrieveQueryError =
  AxiosError<unknown>;

export const useV0TimesheetApprovalsFormSchemaRetrieve = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0TimesheetApprovalsFormSchemaRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0TimesheetApprovalsFormSchemaRetrieveKey() : null));
  const swrFn = () => v0TimesheetApprovalsFormSchemaRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetEntriesList = (
  params?: V0TimesheetEntriesListParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PaginatedTimesheetEntryList>> => {
  return axios.get(`/de/api/v0/timesheet_entries/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getV0TimesheetEntriesListKey = (
  params?: V0TimesheetEntriesListParams,
) => [`/de/api/v0/timesheet_entries/`, ...(params ? [params] : [])] as const;

export type V0TimesheetEntriesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetEntriesList>>
>;
export type V0TimesheetEntriesListQueryError = AxiosError<unknown>;

export const useV0TimesheetEntriesList = <TError = AxiosError<unknown>>(
  params?: V0TimesheetEntriesListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0TimesheetEntriesList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0TimesheetEntriesListKey(params) : null));
  const swrFn = () => v0TimesheetEntriesList(params, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetEntriesUpdate = (
  id: string,
  timesheetEntry: NonReadonly<TimesheetEntry>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<TimesheetEntry>> => {
  return axios.put(
    `/de/api/v0/timesheet_entries/${id}/`,
    timesheetEntry,
    options,
  );
};

export const getV0TimesheetEntriesUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<TimesheetEntry> },
  ): Promise<AxiosResponse<TimesheetEntry>> => {
    return v0TimesheetEntriesUpdate(id, arg, options);
  };
};
export const getV0TimesheetEntriesUpdateMutationKey = (id: string) =>
  [`/de/api/v0/timesheet_entries/${id}/`] as const;

export type V0TimesheetEntriesUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetEntriesUpdate>>
>;
export type V0TimesheetEntriesUpdateMutationError = AxiosError<unknown>;

export const useV0TimesheetEntriesUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0TimesheetEntriesUpdate>>,
      TError,
      Key,
      NonReadonly<TimesheetEntry>,
      Awaited<ReturnType<typeof v0TimesheetEntriesUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0TimesheetEntriesUpdateMutationKey(id);
  const swrFn = getV0TimesheetEntriesUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetEntriesPartialUpdate = (
  id: string,
  patchedTimesheetEntry: NonReadonly<PatchedTimesheetEntry>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<TimesheetEntry>> => {
  return axios.patch(
    `/de/api/v0/timesheet_entries/${id}/`,
    patchedTimesheetEntry,
    options,
  );
};

export const getV0TimesheetEntriesPartialUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PatchedTimesheetEntry> },
  ): Promise<AxiosResponse<TimesheetEntry>> => {
    return v0TimesheetEntriesPartialUpdate(id, arg, options);
  };
};
export const getV0TimesheetEntriesPartialUpdateMutationKey = (id: string) =>
  [`/de/api/v0/timesheet_entries/${id}/`] as const;

export type V0TimesheetEntriesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetEntriesPartialUpdate>>
>;
export type V0TimesheetEntriesPartialUpdateMutationError = AxiosError<unknown>;

export const useV0TimesheetEntriesPartialUpdate = <
  TError = AxiosError<unknown>,
>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0TimesheetEntriesPartialUpdate>>,
      TError,
      Key,
      NonReadonly<PatchedTimesheetEntry>,
      Awaited<ReturnType<typeof v0TimesheetEntriesPartialUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0TimesheetEntriesPartialUpdateMutationKey(id);
  const swrFn = getV0TimesheetEntriesPartialUpdateMutationFetcher(
    id,
    axiosOptions,
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetEntriesFormSchemaRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<TimesheetEntry>> => {
  return axios.get(`/de/api/v0/timesheet_entries/form_schema/`, options);
};

export const getV0TimesheetEntriesFormSchemaRetrieveKey = () =>
  [`/de/api/v0/timesheet_entries/form_schema/`] as const;

export type V0TimesheetEntriesFormSchemaRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetEntriesFormSchemaRetrieve>>
>;
export type V0TimesheetEntriesFormSchemaRetrieveQueryError =
  AxiosError<unknown>;

export const useV0TimesheetEntriesFormSchemaRetrieve = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0TimesheetEntriesFormSchemaRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0TimesheetEntriesFormSchemaRetrieveKey() : null));
  const swrFn = () => v0TimesheetEntriesFormSchemaRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetsList = (
  params?: V0TimesheetsListParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PaginatedTimesheetList>> => {
  return axios.get(`/de/api/v0/timesheets/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getV0TimesheetsListKey = (params?: V0TimesheetsListParams) =>
  [`/de/api/v0/timesheets/`, ...(params ? [params] : [])] as const;

export type V0TimesheetsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetsList>>
>;
export type V0TimesheetsListQueryError = AxiosError<unknown>;

export const useV0TimesheetsList = <TError = AxiosError<unknown>>(
  params?: V0TimesheetsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0TimesheetsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0TimesheetsListKey(params) : null));
  const swrFn = () => v0TimesheetsList(params, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetsCreate = (
  timesheet: NonReadonly<Timesheet>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Timesheet>> => {
  return axios.post(`/de/api/v0/timesheets/`, timesheet, options);
};

export const getV0TimesheetsCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Timesheet> },
  ): Promise<AxiosResponse<Timesheet>> => {
    return v0TimesheetsCreate(arg, options);
  };
};
export const getV0TimesheetsCreateMutationKey = () =>
  [`/de/api/v0/timesheets/`] as const;

export type V0TimesheetsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetsCreate>>
>;
export type V0TimesheetsCreateMutationError = AxiosError<unknown>;

export const useV0TimesheetsCreate = <TError = AxiosError<unknown>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof v0TimesheetsCreate>>,
    TError,
    Key,
    NonReadonly<Timesheet>,
    Awaited<ReturnType<typeof v0TimesheetsCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0TimesheetsCreateMutationKey();
  const swrFn = getV0TimesheetsCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetsRetrieve = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Timesheet>> => {
  return axios.get(`/de/api/v0/timesheets/${id}/`, options);
};

export const getV0TimesheetsRetrieveKey = (id: string) =>
  [`/de/api/v0/timesheets/${id}/`] as const;

export type V0TimesheetsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetsRetrieve>>
>;
export type V0TimesheetsRetrieveQueryError = AxiosError<unknown>;

export const useV0TimesheetsRetrieve = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0TimesheetsRetrieve>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0TimesheetsRetrieveKey(id) : null));
  const swrFn = () => v0TimesheetsRetrieve(id, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetsUpdate = (
  id: string,
  timesheet: NonReadonly<Timesheet>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Timesheet>> => {
  return axios.put(`/de/api/v0/timesheets/${id}/`, timesheet, options);
};

export const getV0TimesheetsUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Timesheet> },
  ): Promise<AxiosResponse<Timesheet>> => {
    return v0TimesheetsUpdate(id, arg, options);
  };
};
export const getV0TimesheetsUpdateMutationKey = (id: string) =>
  [`/de/api/v0/timesheets/${id}/`] as const;

export type V0TimesheetsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetsUpdate>>
>;
export type V0TimesheetsUpdateMutationError = AxiosError<unknown>;

export const useV0TimesheetsUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0TimesheetsUpdate>>,
      TError,
      Key,
      NonReadonly<Timesheet>,
      Awaited<ReturnType<typeof v0TimesheetsUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0TimesheetsUpdateMutationKey(id);
  const swrFn = getV0TimesheetsUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetsPartialUpdate = (
  id: string,
  patchedTimesheet: NonReadonly<PatchedTimesheet>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Timesheet>> => {
  return axios.patch(`/de/api/v0/timesheets/${id}/`, patchedTimesheet, options);
};

export const getV0TimesheetsPartialUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PatchedTimesheet> },
  ): Promise<AxiosResponse<Timesheet>> => {
    return v0TimesheetsPartialUpdate(id, arg, options);
  };
};
export const getV0TimesheetsPartialUpdateMutationKey = (id: string) =>
  [`/de/api/v0/timesheets/${id}/`] as const;

export type V0TimesheetsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetsPartialUpdate>>
>;
export type V0TimesheetsPartialUpdateMutationError = AxiosError<unknown>;

export const useV0TimesheetsPartialUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0TimesheetsPartialUpdate>>,
      TError,
      Key,
      NonReadonly<PatchedTimesheet>,
      Awaited<ReturnType<typeof v0TimesheetsPartialUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0TimesheetsPartialUpdateMutationKey(id);
  const swrFn = getV0TimesheetsPartialUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetsDestroy = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/de/api/v0/timesheets/${id}/`, options);
};

export const getV0TimesheetsDestroyMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<AxiosResponse<void>> => {
    return v0TimesheetsDestroy(id, options);
  };
};
export const getV0TimesheetsDestroyMutationKey = (id: string) =>
  [`/de/api/v0/timesheets/${id}/`] as const;

export type V0TimesheetsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetsDestroy>>
>;
export type V0TimesheetsDestroyMutationError = AxiosError<unknown>;

export const useV0TimesheetsDestroy = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0TimesheetsDestroy>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof v0TimesheetsDestroy>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0TimesheetsDestroyMutationKey(id);
  const swrFn = getV0TimesheetsDestroyMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetsNotifyApproversCreate = (
  id: string,
  timesheet: NonReadonly<Timesheet>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Timesheet>> => {
  return axios.post(
    `/de/api/v0/timesheets/${id}/notify_approvers/`,
    timesheet,
    options,
  );
};

export const getV0TimesheetsNotifyApproversCreateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Timesheet> },
  ): Promise<AxiosResponse<Timesheet>> => {
    return v0TimesheetsNotifyApproversCreate(id, arg, options);
  };
};
export const getV0TimesheetsNotifyApproversCreateMutationKey = (id: string) =>
  [`/de/api/v0/timesheets/${id}/notify_approvers/`] as const;

export type V0TimesheetsNotifyApproversCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetsNotifyApproversCreate>>
>;
export type V0TimesheetsNotifyApproversCreateMutationError =
  AxiosError<unknown>;

export const useV0TimesheetsNotifyApproversCreate = <
  TError = AxiosError<unknown>,
>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0TimesheetsNotifyApproversCreate>>,
      TError,
      Key,
      NonReadonly<Timesheet>,
      Awaited<ReturnType<typeof v0TimesheetsNotifyApproversCreate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0TimesheetsNotifyApproversCreateMutationKey(id);
  const swrFn = getV0TimesheetsNotifyApproversCreateMutationFetcher(
    id,
    axiosOptions,
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

/**
 * Returns timesheet entry analytics.
 */
export const v0TimesheetsAnalyticsRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Timesheet>> => {
  return axios.get(`/de/api/v0/timesheets/analytics/`, options);
};

export const getV0TimesheetsAnalyticsRetrieveKey = () =>
  [`/de/api/v0/timesheets/analytics/`] as const;

export type V0TimesheetsAnalyticsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetsAnalyticsRetrieve>>
>;
export type V0TimesheetsAnalyticsRetrieveQueryError = AxiosError<unknown>;

export const useV0TimesheetsAnalyticsRetrieve = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0TimesheetsAnalyticsRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0TimesheetsAnalyticsRetrieveKey() : null));
  const swrFn = () => v0TimesheetsAnalyticsRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetsAnalyticsCurrentMonthRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Timesheet>> => {
  return axios.get(`/de/api/v0/timesheets/analytics_current_month/`, options);
};

export const getV0TimesheetsAnalyticsCurrentMonthRetrieveKey = () =>
  [`/de/api/v0/timesheets/analytics_current_month/`] as const;

export type V0TimesheetsAnalyticsCurrentMonthRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetsAnalyticsCurrentMonthRetrieve>>
>;
export type V0TimesheetsAnalyticsCurrentMonthRetrieveQueryError =
  AxiosError<unknown>;

export const useV0TimesheetsAnalyticsCurrentMonthRetrieve = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0TimesheetsAnalyticsCurrentMonthRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getV0TimesheetsAnalyticsCurrentMonthRetrieveKey() : null);
  const swrFn = () => v0TimesheetsAnalyticsCurrentMonthRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0TimesheetsFormSchemaRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Timesheet>> => {
  return axios.get(`/de/api/v0/timesheets/form_schema/`, options);
};

export const getV0TimesheetsFormSchemaRetrieveKey = () =>
  [`/de/api/v0/timesheets/form_schema/`] as const;

export type V0TimesheetsFormSchemaRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0TimesheetsFormSchemaRetrieve>>
>;
export type V0TimesheetsFormSchemaRetrieveQueryError = AxiosError<unknown>;

export const useV0TimesheetsFormSchemaRetrieve = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0TimesheetsFormSchemaRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0TimesheetsFormSchemaRetrieveKey() : null));
  const swrFn = () => v0TimesheetsFormSchemaRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0TrackersList = (
  params?: V0TrackersListParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PaginatedTrackerList>> => {
  return axios.get(`/de/api/v0/trackers/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getV0TrackersListKey = (params?: V0TrackersListParams) =>
  [`/de/api/v0/trackers/`, ...(params ? [params] : [])] as const;

export type V0TrackersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0TrackersList>>
>;
export type V0TrackersListQueryError = AxiosError<unknown>;

export const useV0TrackersList = <TError = AxiosError<unknown>>(
  params?: V0TrackersListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0TrackersList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0TrackersListKey(params) : null));
  const swrFn = () => v0TrackersList(params, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0TrackersCreate = (
  tracker: NonReadonly<Tracker>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Tracker>> => {
  return axios.post(`/de/api/v0/trackers/`, tracker, options);
};

export const getV0TrackersCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Tracker> },
  ): Promise<AxiosResponse<Tracker>> => {
    return v0TrackersCreate(arg, options);
  };
};
export const getV0TrackersCreateMutationKey = () =>
  [`/de/api/v0/trackers/`] as const;

export type V0TrackersCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0TrackersCreate>>
>;
export type V0TrackersCreateMutationError = AxiosError<unknown>;

export const useV0TrackersCreate = <TError = AxiosError<unknown>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof v0TrackersCreate>>,
    TError,
    Key,
    NonReadonly<Tracker>,
    Awaited<ReturnType<typeof v0TrackersCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0TrackersCreateMutationKey();
  const swrFn = getV0TrackersCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0TrackersRetrieve = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Tracker>> => {
  return axios.get(`/de/api/v0/trackers/${id}/`, options);
};

export const getV0TrackersRetrieveKey = (id: string) =>
  [`/de/api/v0/trackers/${id}/`] as const;

export type V0TrackersRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0TrackersRetrieve>>
>;
export type V0TrackersRetrieveQueryError = AxiosError<unknown>;

export const useV0TrackersRetrieve = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0TrackersRetrieve>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0TrackersRetrieveKey(id) : null));
  const swrFn = () => v0TrackersRetrieve(id, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0TrackersUpdate = (
  id: string,
  tracker: NonReadonly<Tracker>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Tracker>> => {
  return axios.put(`/de/api/v0/trackers/${id}/`, tracker, options);
};

export const getV0TrackersUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Tracker> },
  ): Promise<AxiosResponse<Tracker>> => {
    return v0TrackersUpdate(id, arg, options);
  };
};
export const getV0TrackersUpdateMutationKey = (id: string) =>
  [`/de/api/v0/trackers/${id}/`] as const;

export type V0TrackersUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0TrackersUpdate>>
>;
export type V0TrackersUpdateMutationError = AxiosError<unknown>;

export const useV0TrackersUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0TrackersUpdate>>,
      TError,
      Key,
      NonReadonly<Tracker>,
      Awaited<ReturnType<typeof v0TrackersUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0TrackersUpdateMutationKey(id);
  const swrFn = getV0TrackersUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0TrackersPartialUpdate = (
  id: string,
  patchedTracker: NonReadonly<PatchedTracker>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Tracker>> => {
  return axios.patch(`/de/api/v0/trackers/${id}/`, patchedTracker, options);
};

export const getV0TrackersPartialUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PatchedTracker> },
  ): Promise<AxiosResponse<Tracker>> => {
    return v0TrackersPartialUpdate(id, arg, options);
  };
};
export const getV0TrackersPartialUpdateMutationKey = (id: string) =>
  [`/de/api/v0/trackers/${id}/`] as const;

export type V0TrackersPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0TrackersPartialUpdate>>
>;
export type V0TrackersPartialUpdateMutationError = AxiosError<unknown>;

export const useV0TrackersPartialUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0TrackersPartialUpdate>>,
      TError,
      Key,
      NonReadonly<PatchedTracker>,
      Awaited<ReturnType<typeof v0TrackersPartialUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0TrackersPartialUpdateMutationKey(id);
  const swrFn = getV0TrackersPartialUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0TrackersDestroy = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/de/api/v0/trackers/${id}/`, options);
};

export const getV0TrackersDestroyMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<AxiosResponse<void>> => {
    return v0TrackersDestroy(id, options);
  };
};
export const getV0TrackersDestroyMutationKey = (id: string) =>
  [`/de/api/v0/trackers/${id}/`] as const;

export type V0TrackersDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0TrackersDestroy>>
>;
export type V0TrackersDestroyMutationError = AxiosError<unknown>;

export const useV0TrackersDestroy = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0TrackersDestroy>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof v0TrackersDestroy>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0TrackersDestroyMutationKey(id);
  const swrFn = getV0TrackersDestroyMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0TrackersFormSchemaRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Tracker>> => {
  return axios.get(`/de/api/v0/trackers/form_schema/`, options);
};

export const getV0TrackersFormSchemaRetrieveKey = () =>
  [`/de/api/v0/trackers/form_schema/`] as const;

export type V0TrackersFormSchemaRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0TrackersFormSchemaRetrieve>>
>;
export type V0TrackersFormSchemaRetrieveQueryError = AxiosError<unknown>;

export const useV0TrackersFormSchemaRetrieve = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0TrackersFormSchemaRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0TrackersFormSchemaRetrieveKey() : null));
  const swrFn = () => v0TrackersFormSchemaRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * A viewset that provides default `update` on user.
 */
export const v0UsersList = (
  params?: V0UsersListParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PaginatedUserList>> => {
  return axios.get(`/de/api/v0/users/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getV0UsersListKey = (params?: V0UsersListParams) =>
  [`/de/api/v0/users/`, ...(params ? [params] : [])] as const;

export type V0UsersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0UsersList>>
>;
export type V0UsersListQueryError = AxiosError<unknown>;

export const useV0UsersList = <TError = AxiosError<unknown>>(
  params?: V0UsersListParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof v0UsersList>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0UsersListKey(params) : null));
  const swrFn = () => v0UsersList(params, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * A viewset that provides default `update` on user.
 */
export const v0UsersCreate = (
  user: NonReadonly<User>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<User>> => {
  return axios.post(`/de/api/v0/users/`, user, options);
};

export const getV0UsersCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<User> },
  ): Promise<AxiosResponse<User>> => {
    return v0UsersCreate(arg, options);
  };
};
export const getV0UsersCreateMutationKey = () => [`/de/api/v0/users/`] as const;

export type V0UsersCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0UsersCreate>>
>;
export type V0UsersCreateMutationError = AxiosError<unknown>;

export const useV0UsersCreate = <TError = AxiosError<unknown>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof v0UsersCreate>>,
    TError,
    Key,
    NonReadonly<User>,
    Awaited<ReturnType<typeof v0UsersCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0UsersCreateMutationKey();
  const swrFn = getV0UsersCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

/**
 * A viewset that provides default `update` on user.
 */
export const v0UsersRetrieve = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<User>> => {
  return axios.get(`/de/api/v0/users/${id}/`, options);
};

export const getV0UsersRetrieveKey = (id: string) =>
  [`/de/api/v0/users/${id}/`] as const;

export type V0UsersRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0UsersRetrieve>>
>;
export type V0UsersRetrieveQueryError = AxiosError<unknown>;

export const useV0UsersRetrieve = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0UsersRetrieve>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0UsersRetrieveKey(id) : null));
  const swrFn = () => v0UsersRetrieve(id, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * A viewset that provides default `update` on user.
 */
export const v0UsersUpdate = (
  id: string,
  user: NonReadonly<User>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<User>> => {
  return axios.put(`/de/api/v0/users/${id}/`, user, options);
};

export const getV0UsersUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<User> },
  ): Promise<AxiosResponse<User>> => {
    return v0UsersUpdate(id, arg, options);
  };
};
export const getV0UsersUpdateMutationKey = (id: string) =>
  [`/de/api/v0/users/${id}/`] as const;

export type V0UsersUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0UsersUpdate>>
>;
export type V0UsersUpdateMutationError = AxiosError<unknown>;

export const useV0UsersUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0UsersUpdate>>,
      TError,
      Key,
      NonReadonly<User>,
      Awaited<ReturnType<typeof v0UsersUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0UsersUpdateMutationKey(id);
  const swrFn = getV0UsersUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

/**
 * A viewset that provides default `update` on user.
 */
export const v0UsersPartialUpdate = (
  id: string,
  patchedUser: NonReadonly<PatchedUser>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<User>> => {
  return axios.patch(`/de/api/v0/users/${id}/`, patchedUser, options);
};

export const getV0UsersPartialUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PatchedUser> },
  ): Promise<AxiosResponse<User>> => {
    return v0UsersPartialUpdate(id, arg, options);
  };
};
export const getV0UsersPartialUpdateMutationKey = (id: string) =>
  [`/de/api/v0/users/${id}/`] as const;

export type V0UsersPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0UsersPartialUpdate>>
>;
export type V0UsersPartialUpdateMutationError = AxiosError<unknown>;

export const useV0UsersPartialUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0UsersPartialUpdate>>,
      TError,
      Key,
      NonReadonly<PatchedUser>,
      Awaited<ReturnType<typeof v0UsersPartialUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0UsersPartialUpdateMutationKey(id);
  const swrFn = getV0UsersPartialUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

/**
 * A viewset that provides default `update` on user.
 */
export const v0UsersDestroy = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/de/api/v0/users/${id}/`, options);
};

export const getV0UsersDestroyMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<AxiosResponse<void>> => {
    return v0UsersDestroy(id, options);
  };
};
export const getV0UsersDestroyMutationKey = (id: string) =>
  [`/de/api/v0/users/${id}/`] as const;

export type V0UsersDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0UsersDestroy>>
>;
export type V0UsersDestroyMutationError = AxiosError<unknown>;

export const useV0UsersDestroy = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0UsersDestroy>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof v0UsersDestroy>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0UsersDestroyMutationKey(id);
  const swrFn = getV0UsersDestroyMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

/**
 * A viewset that provides default `update` on user.
 */
export const v0UsersFormSchemaRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<User>> => {
  return axios.get(`/de/api/v0/users/form_schema/`, options);
};

export const getV0UsersFormSchemaRetrieveKey = () =>
  [`/de/api/v0/users/form_schema/`] as const;

export type V0UsersFormSchemaRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0UsersFormSchemaRetrieve>>
>;
export type V0UsersFormSchemaRetrieveQueryError = AxiosError<unknown>;

export const useV0UsersFormSchemaRetrieve = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0UsersFormSchemaRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0UsersFormSchemaRetrieveKey() : null));
  const swrFn = () => v0UsersFormSchemaRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * A viewset that provides default `update` on user.
 */
export const v0UsersMeRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<UserMe>> => {
  return axios.get(`/de/api/v0/users/me/`, options);
};

export const getV0UsersMeRetrieveKey = () => [`/de/api/v0/users/me/`] as const;

export type V0UsersMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0UsersMeRetrieve>>
>;
export type V0UsersMeRetrieveQueryError = AxiosError<unknown>;

export const useV0UsersMeRetrieve = <TError = AxiosError<unknown>>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0UsersMeRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0UsersMeRetrieveKey() : null));
  const swrFn = () => v0UsersMeRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryEducationsList = (
  params?: V0VitacurryEducationsListParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PaginatedEducationList>> => {
  return axios.get(`/de/api/v0/vitacurry/educations/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getV0VitacurryEducationsListKey = (
  params?: V0VitacurryEducationsListParams,
) => [`/de/api/v0/vitacurry/educations/`, ...(params ? [params] : [])] as const;

export type V0VitacurryEducationsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryEducationsList>>
>;
export type V0VitacurryEducationsListQueryError = AxiosError<unknown>;

export const useV0VitacurryEducationsList = <TError = AxiosError<unknown>>(
  params?: V0VitacurryEducationsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0VitacurryEducationsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0VitacurryEducationsListKey(params) : null));
  const swrFn = () => v0VitacurryEducationsList(params, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryEducationsCreate = (
  education: NonReadonly<Education>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Education>> => {
  return axios.post(`/de/api/v0/vitacurry/educations/`, education, options);
};

export const getV0VitacurryEducationsCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Education> },
  ): Promise<AxiosResponse<Education>> => {
    return v0VitacurryEducationsCreate(arg, options);
  };
};
export const getV0VitacurryEducationsCreateMutationKey = () =>
  [`/de/api/v0/vitacurry/educations/`] as const;

export type V0VitacurryEducationsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryEducationsCreate>>
>;
export type V0VitacurryEducationsCreateMutationError = AxiosError<unknown>;

export const useV0VitacurryEducationsCreate = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof v0VitacurryEducationsCreate>>,
    TError,
    Key,
    NonReadonly<Education>,
    Awaited<ReturnType<typeof v0VitacurryEducationsCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurryEducationsCreateMutationKey();
  const swrFn = getV0VitacurryEducationsCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryEducationsRetrieve = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Education>> => {
  return axios.get(`/de/api/v0/vitacurry/educations/${id}/`, options);
};

export const getV0VitacurryEducationsRetrieveKey = (id: string) =>
  [`/de/api/v0/vitacurry/educations/${id}/`] as const;

export type V0VitacurryEducationsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryEducationsRetrieve>>
>;
export type V0VitacurryEducationsRetrieveQueryError = AxiosError<unknown>;

export const useV0VitacurryEducationsRetrieve = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0VitacurryEducationsRetrieve>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0VitacurryEducationsRetrieveKey(id) : null));
  const swrFn = () => v0VitacurryEducationsRetrieve(id, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryEducationsUpdate = (
  id: string,
  education: NonReadonly<Education>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Education>> => {
  return axios.put(
    `/de/api/v0/vitacurry/educations/${id}/`,
    education,
    options,
  );
};

export const getV0VitacurryEducationsUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Education> },
  ): Promise<AxiosResponse<Education>> => {
    return v0VitacurryEducationsUpdate(id, arg, options);
  };
};
export const getV0VitacurryEducationsUpdateMutationKey = (id: string) =>
  [`/de/api/v0/vitacurry/educations/${id}/`] as const;

export type V0VitacurryEducationsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryEducationsUpdate>>
>;
export type V0VitacurryEducationsUpdateMutationError = AxiosError<unknown>;

export const useV0VitacurryEducationsUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0VitacurryEducationsUpdate>>,
      TError,
      Key,
      NonReadonly<Education>,
      Awaited<ReturnType<typeof v0VitacurryEducationsUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurryEducationsUpdateMutationKey(id);
  const swrFn = getV0VitacurryEducationsUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryEducationsPartialUpdate = (
  id: string,
  patchedEducation: NonReadonly<PatchedEducation>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Education>> => {
  return axios.patch(
    `/de/api/v0/vitacurry/educations/${id}/`,
    patchedEducation,
    options,
  );
};

export const getV0VitacurryEducationsPartialUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PatchedEducation> },
  ): Promise<AxiosResponse<Education>> => {
    return v0VitacurryEducationsPartialUpdate(id, arg, options);
  };
};
export const getV0VitacurryEducationsPartialUpdateMutationKey = (id: string) =>
  [`/de/api/v0/vitacurry/educations/${id}/`] as const;

export type V0VitacurryEducationsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryEducationsPartialUpdate>>
>;
export type V0VitacurryEducationsPartialUpdateMutationError =
  AxiosError<unknown>;

export const useV0VitacurryEducationsPartialUpdate = <
  TError = AxiosError<unknown>,
>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0VitacurryEducationsPartialUpdate>>,
      TError,
      Key,
      NonReadonly<PatchedEducation>,
      Awaited<ReturnType<typeof v0VitacurryEducationsPartialUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurryEducationsPartialUpdateMutationKey(id);
  const swrFn = getV0VitacurryEducationsPartialUpdateMutationFetcher(
    id,
    axiosOptions,
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryEducationsDestroy = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/de/api/v0/vitacurry/educations/${id}/`, options);
};

export const getV0VitacurryEducationsDestroyMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<AxiosResponse<void>> => {
    return v0VitacurryEducationsDestroy(id, options);
  };
};
export const getV0VitacurryEducationsDestroyMutationKey = (id: string) =>
  [`/de/api/v0/vitacurry/educations/${id}/`] as const;

export type V0VitacurryEducationsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryEducationsDestroy>>
>;
export type V0VitacurryEducationsDestroyMutationError = AxiosError<unknown>;

export const useV0VitacurryEducationsDestroy = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0VitacurryEducationsDestroy>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof v0VitacurryEducationsDestroy>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurryEducationsDestroyMutationKey(id);
  const swrFn = getV0VitacurryEducationsDestroyMutationFetcher(
    id,
    axiosOptions,
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryEducationsFormSchemaRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Education>> => {
  return axios.get(`/de/api/v0/vitacurry/educations/form_schema/`, options);
};

export const getV0VitacurryEducationsFormSchemaRetrieveKey = () =>
  [`/de/api/v0/vitacurry/educations/form_schema/`] as const;

export type V0VitacurryEducationsFormSchemaRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryEducationsFormSchemaRetrieve>>
>;
export type V0VitacurryEducationsFormSchemaRetrieveQueryError =
  AxiosError<unknown>;

export const useV0VitacurryEducationsFormSchemaRetrieve = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0VitacurryEducationsFormSchemaRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getV0VitacurryEducationsFormSchemaRetrieveKey() : null);
  const swrFn = () => v0VitacurryEducationsFormSchemaRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryExternalProfilesList = (
  params?: V0VitacurryExternalProfilesListParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PaginatedExternalProfileList>> => {
  return axios.get(`/de/api/v0/vitacurry/external_profiles/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getV0VitacurryExternalProfilesListKey = (
  params?: V0VitacurryExternalProfilesListParams,
) =>
  [
    `/de/api/v0/vitacurry/external_profiles/`,
    ...(params ? [params] : []),
  ] as const;

export type V0VitacurryExternalProfilesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryExternalProfilesList>>
>;
export type V0VitacurryExternalProfilesListQueryError = AxiosError<unknown>;

export const useV0VitacurryExternalProfilesList = <
  TError = AxiosError<unknown>,
>(
  params?: V0VitacurryExternalProfilesListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0VitacurryExternalProfilesList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0VitacurryExternalProfilesListKey(params) : null));
  const swrFn = () => v0VitacurryExternalProfilesList(params, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryExternalProfilesCreate = (
  externalProfile: NonReadonly<ExternalProfile>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ExternalProfile>> => {
  return axios.post(
    `/de/api/v0/vitacurry/external_profiles/`,
    externalProfile,
    options,
  );
};

export const getV0VitacurryExternalProfilesCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<ExternalProfile> },
  ): Promise<AxiosResponse<ExternalProfile>> => {
    return v0VitacurryExternalProfilesCreate(arg, options);
  };
};
export const getV0VitacurryExternalProfilesCreateMutationKey = () =>
  [`/de/api/v0/vitacurry/external_profiles/`] as const;

export type V0VitacurryExternalProfilesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryExternalProfilesCreate>>
>;
export type V0VitacurryExternalProfilesCreateMutationError =
  AxiosError<unknown>;

export const useV0VitacurryExternalProfilesCreate = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof v0VitacurryExternalProfilesCreate>>,
    TError,
    Key,
    NonReadonly<ExternalProfile>,
    Awaited<ReturnType<typeof v0VitacurryExternalProfilesCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurryExternalProfilesCreateMutationKey();
  const swrFn =
    getV0VitacurryExternalProfilesCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryExternalProfilesRetrieve = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ExternalProfile>> => {
  return axios.get(`/de/api/v0/vitacurry/external_profiles/${id}/`, options);
};

export const getV0VitacurryExternalProfilesRetrieveKey = (id: string) =>
  [`/de/api/v0/vitacurry/external_profiles/${id}/`] as const;

export type V0VitacurryExternalProfilesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryExternalProfilesRetrieve>>
>;
export type V0VitacurryExternalProfilesRetrieveQueryError = AxiosError<unknown>;

export const useV0VitacurryExternalProfilesRetrieve = <
  TError = AxiosError<unknown>,
>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0VitacurryExternalProfilesRetrieve>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0VitacurryExternalProfilesRetrieveKey(id) : null));
  const swrFn = () => v0VitacurryExternalProfilesRetrieve(id, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryExternalProfilesUpdate = (
  id: string,
  externalProfile: NonReadonly<ExternalProfile>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ExternalProfile>> => {
  return axios.put(
    `/de/api/v0/vitacurry/external_profiles/${id}/`,
    externalProfile,
    options,
  );
};

export const getV0VitacurryExternalProfilesUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<ExternalProfile> },
  ): Promise<AxiosResponse<ExternalProfile>> => {
    return v0VitacurryExternalProfilesUpdate(id, arg, options);
  };
};
export const getV0VitacurryExternalProfilesUpdateMutationKey = (id: string) =>
  [`/de/api/v0/vitacurry/external_profiles/${id}/`] as const;

export type V0VitacurryExternalProfilesUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryExternalProfilesUpdate>>
>;
export type V0VitacurryExternalProfilesUpdateMutationError =
  AxiosError<unknown>;

export const useV0VitacurryExternalProfilesUpdate = <
  TError = AxiosError<unknown>,
>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0VitacurryExternalProfilesUpdate>>,
      TError,
      Key,
      NonReadonly<ExternalProfile>,
      Awaited<ReturnType<typeof v0VitacurryExternalProfilesUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurryExternalProfilesUpdateMutationKey(id);
  const swrFn = getV0VitacurryExternalProfilesUpdateMutationFetcher(
    id,
    axiosOptions,
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryExternalProfilesPartialUpdate = (
  id: string,
  patchedExternalProfile: NonReadonly<PatchedExternalProfile>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ExternalProfile>> => {
  return axios.patch(
    `/de/api/v0/vitacurry/external_profiles/${id}/`,
    patchedExternalProfile,
    options,
  );
};

export const getV0VitacurryExternalProfilesPartialUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PatchedExternalProfile> },
  ): Promise<AxiosResponse<ExternalProfile>> => {
    return v0VitacurryExternalProfilesPartialUpdate(id, arg, options);
  };
};
export const getV0VitacurryExternalProfilesPartialUpdateMutationKey = (
  id: string,
) => [`/de/api/v0/vitacurry/external_profiles/${id}/`] as const;

export type V0VitacurryExternalProfilesPartialUpdateMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof v0VitacurryExternalProfilesPartialUpdate>>
  >;
export type V0VitacurryExternalProfilesPartialUpdateMutationError =
  AxiosError<unknown>;

export const useV0VitacurryExternalProfilesPartialUpdate = <
  TError = AxiosError<unknown>,
>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0VitacurryExternalProfilesPartialUpdate>>,
      TError,
      Key,
      NonReadonly<PatchedExternalProfile>,
      Awaited<ReturnType<typeof v0VitacurryExternalProfilesPartialUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ??
    getV0VitacurryExternalProfilesPartialUpdateMutationKey(id);
  const swrFn = getV0VitacurryExternalProfilesPartialUpdateMutationFetcher(
    id,
    axiosOptions,
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryExternalProfilesDestroy = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/de/api/v0/vitacurry/external_profiles/${id}/`, options);
};

export const getV0VitacurryExternalProfilesDestroyMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<AxiosResponse<void>> => {
    return v0VitacurryExternalProfilesDestroy(id, options);
  };
};
export const getV0VitacurryExternalProfilesDestroyMutationKey = (id: string) =>
  [`/de/api/v0/vitacurry/external_profiles/${id}/`] as const;

export type V0VitacurryExternalProfilesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryExternalProfilesDestroy>>
>;
export type V0VitacurryExternalProfilesDestroyMutationError =
  AxiosError<unknown>;

export const useV0VitacurryExternalProfilesDestroy = <
  TError = AxiosError<unknown>,
>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0VitacurryExternalProfilesDestroy>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof v0VitacurryExternalProfilesDestroy>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurryExternalProfilesDestroyMutationKey(id);
  const swrFn = getV0VitacurryExternalProfilesDestroyMutationFetcher(
    id,
    axiosOptions,
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryExternalProfilesFormSchemaRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<ExternalProfile>> => {
  return axios.get(
    `/de/api/v0/vitacurry/external_profiles/form_schema/`,
    options,
  );
};

export const getV0VitacurryExternalProfilesFormSchemaRetrieveKey = () =>
  [`/de/api/v0/vitacurry/external_profiles/form_schema/`] as const;

export type V0VitacurryExternalProfilesFormSchemaRetrieveQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof v0VitacurryExternalProfilesFormSchemaRetrieve>>
  >;
export type V0VitacurryExternalProfilesFormSchemaRetrieveQueryError =
  AxiosError<unknown>;

export const useV0VitacurryExternalProfilesFormSchemaRetrieve = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0VitacurryExternalProfilesFormSchemaRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getV0VitacurryExternalProfilesFormSchemaRetrieveKey() : null);
  const swrFn = () =>
    v0VitacurryExternalProfilesFormSchemaRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryPortfolioList = (
  params?: V0VitacurryPortfolioListParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PaginatedPortfolioItemList>> => {
  return axios.get(`/de/api/v0/vitacurry/portfolio/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getV0VitacurryPortfolioListKey = (
  params?: V0VitacurryPortfolioListParams,
) => [`/de/api/v0/vitacurry/portfolio/`, ...(params ? [params] : [])] as const;

export type V0VitacurryPortfolioListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryPortfolioList>>
>;
export type V0VitacurryPortfolioListQueryError = AxiosError<unknown>;

export const useV0VitacurryPortfolioList = <TError = AxiosError<unknown>>(
  params?: V0VitacurryPortfolioListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0VitacurryPortfolioList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0VitacurryPortfolioListKey(params) : null));
  const swrFn = () => v0VitacurryPortfolioList(params, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryPortfolioCreate = (
  portfolioItem: NonReadonly<PortfolioItem>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PortfolioItem>> => {
  return axios.post(`/de/api/v0/vitacurry/portfolio/`, portfolioItem, options);
};

export const getV0VitacurryPortfolioCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PortfolioItem> },
  ): Promise<AxiosResponse<PortfolioItem>> => {
    return v0VitacurryPortfolioCreate(arg, options);
  };
};
export const getV0VitacurryPortfolioCreateMutationKey = () =>
  [`/de/api/v0/vitacurry/portfolio/`] as const;

export type V0VitacurryPortfolioCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryPortfolioCreate>>
>;
export type V0VitacurryPortfolioCreateMutationError = AxiosError<unknown>;

export const useV0VitacurryPortfolioCreate = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof v0VitacurryPortfolioCreate>>,
    TError,
    Key,
    NonReadonly<PortfolioItem>,
    Awaited<ReturnType<typeof v0VitacurryPortfolioCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurryPortfolioCreateMutationKey();
  const swrFn = getV0VitacurryPortfolioCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryPortfolioRetrieve = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PortfolioItem>> => {
  return axios.get(`/de/api/v0/vitacurry/portfolio/${id}/`, options);
};

export const getV0VitacurryPortfolioRetrieveKey = (id: string) =>
  [`/de/api/v0/vitacurry/portfolio/${id}/`] as const;

export type V0VitacurryPortfolioRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryPortfolioRetrieve>>
>;
export type V0VitacurryPortfolioRetrieveQueryError = AxiosError<unknown>;

export const useV0VitacurryPortfolioRetrieve = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0VitacurryPortfolioRetrieve>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0VitacurryPortfolioRetrieveKey(id) : null));
  const swrFn = () => v0VitacurryPortfolioRetrieve(id, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryPortfolioUpdate = (
  id: string,
  portfolioItem: NonReadonly<PortfolioItem>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PortfolioItem>> => {
  return axios.put(
    `/de/api/v0/vitacurry/portfolio/${id}/`,
    portfolioItem,
    options,
  );
};

export const getV0VitacurryPortfolioUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PortfolioItem> },
  ): Promise<AxiosResponse<PortfolioItem>> => {
    return v0VitacurryPortfolioUpdate(id, arg, options);
  };
};
export const getV0VitacurryPortfolioUpdateMutationKey = (id: string) =>
  [`/de/api/v0/vitacurry/portfolio/${id}/`] as const;

export type V0VitacurryPortfolioUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryPortfolioUpdate>>
>;
export type V0VitacurryPortfolioUpdateMutationError = AxiosError<unknown>;

export const useV0VitacurryPortfolioUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0VitacurryPortfolioUpdate>>,
      TError,
      Key,
      NonReadonly<PortfolioItem>,
      Awaited<ReturnType<typeof v0VitacurryPortfolioUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurryPortfolioUpdateMutationKey(id);
  const swrFn = getV0VitacurryPortfolioUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryPortfolioPartialUpdate = (
  id: string,
  patchedPortfolioItem: NonReadonly<PatchedPortfolioItem>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PortfolioItem>> => {
  return axios.patch(
    `/de/api/v0/vitacurry/portfolio/${id}/`,
    patchedPortfolioItem,
    options,
  );
};

export const getV0VitacurryPortfolioPartialUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PatchedPortfolioItem> },
  ): Promise<AxiosResponse<PortfolioItem>> => {
    return v0VitacurryPortfolioPartialUpdate(id, arg, options);
  };
};
export const getV0VitacurryPortfolioPartialUpdateMutationKey = (id: string) =>
  [`/de/api/v0/vitacurry/portfolio/${id}/`] as const;

export type V0VitacurryPortfolioPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryPortfolioPartialUpdate>>
>;
export type V0VitacurryPortfolioPartialUpdateMutationError =
  AxiosError<unknown>;

export const useV0VitacurryPortfolioPartialUpdate = <
  TError = AxiosError<unknown>,
>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0VitacurryPortfolioPartialUpdate>>,
      TError,
      Key,
      NonReadonly<PatchedPortfolioItem>,
      Awaited<ReturnType<typeof v0VitacurryPortfolioPartialUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurryPortfolioPartialUpdateMutationKey(id);
  const swrFn = getV0VitacurryPortfolioPartialUpdateMutationFetcher(
    id,
    axiosOptions,
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryPortfolioDestroy = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/de/api/v0/vitacurry/portfolio/${id}/`, options);
};

export const getV0VitacurryPortfolioDestroyMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<AxiosResponse<void>> => {
    return v0VitacurryPortfolioDestroy(id, options);
  };
};
export const getV0VitacurryPortfolioDestroyMutationKey = (id: string) =>
  [`/de/api/v0/vitacurry/portfolio/${id}/`] as const;

export type V0VitacurryPortfolioDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryPortfolioDestroy>>
>;
export type V0VitacurryPortfolioDestroyMutationError = AxiosError<unknown>;

export const useV0VitacurryPortfolioDestroy = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0VitacurryPortfolioDestroy>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof v0VitacurryPortfolioDestroy>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurryPortfolioDestroyMutationKey(id);
  const swrFn = getV0VitacurryPortfolioDestroyMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryPortfolioFormSchemaRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PortfolioItem>> => {
  return axios.get(`/de/api/v0/vitacurry/portfolio/form_schema/`, options);
};

export const getV0VitacurryPortfolioFormSchemaRetrieveKey = () =>
  [`/de/api/v0/vitacurry/portfolio/form_schema/`] as const;

export type V0VitacurryPortfolioFormSchemaRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryPortfolioFormSchemaRetrieve>>
>;
export type V0VitacurryPortfolioFormSchemaRetrieveQueryError =
  AxiosError<unknown>;

export const useV0VitacurryPortfolioFormSchemaRetrieve = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0VitacurryPortfolioFormSchemaRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0VitacurryPortfolioFormSchemaRetrieveKey() : null));
  const swrFn = () => v0VitacurryPortfolioFormSchemaRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryProfilesList = (
  params?: V0VitacurryProfilesListParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PaginatedProfileList>> => {
  return axios.get(`/de/api/v0/vitacurry/profiles/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getV0VitacurryProfilesListKey = (
  params?: V0VitacurryProfilesListParams,
) => [`/de/api/v0/vitacurry/profiles/`, ...(params ? [params] : [])] as const;

export type V0VitacurryProfilesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryProfilesList>>
>;
export type V0VitacurryProfilesListQueryError = AxiosError<unknown>;

export const useV0VitacurryProfilesList = <TError = AxiosError<unknown>>(
  params?: V0VitacurryProfilesListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0VitacurryProfilesList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0VitacurryProfilesListKey(params) : null));
  const swrFn = () => v0VitacurryProfilesList(params, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryProfilesCreate = (
  profile: NonReadonly<Profile>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Profile>> => {
  return axios.post(`/de/api/v0/vitacurry/profiles/`, profile, options);
};

export const getV0VitacurryProfilesCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Profile> },
  ): Promise<AxiosResponse<Profile>> => {
    return v0VitacurryProfilesCreate(arg, options);
  };
};
export const getV0VitacurryProfilesCreateMutationKey = () =>
  [`/de/api/v0/vitacurry/profiles/`] as const;

export type V0VitacurryProfilesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryProfilesCreate>>
>;
export type V0VitacurryProfilesCreateMutationError = AxiosError<unknown>;

export const useV0VitacurryProfilesCreate = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof v0VitacurryProfilesCreate>>,
    TError,
    Key,
    NonReadonly<Profile>,
    Awaited<ReturnType<typeof v0VitacurryProfilesCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurryProfilesCreateMutationKey();
  const swrFn = getV0VitacurryProfilesCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryProfilesRetrieve = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Profile>> => {
  return axios.get(`/de/api/v0/vitacurry/profiles/${id}/`, options);
};

export const getV0VitacurryProfilesRetrieveKey = (id: string) =>
  [`/de/api/v0/vitacurry/profiles/${id}/`] as const;

export type V0VitacurryProfilesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryProfilesRetrieve>>
>;
export type V0VitacurryProfilesRetrieveQueryError = AxiosError<unknown>;

export const useV0VitacurryProfilesRetrieve = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0VitacurryProfilesRetrieve>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0VitacurryProfilesRetrieveKey(id) : null));
  const swrFn = () => v0VitacurryProfilesRetrieve(id, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryProfilesUpdate = (
  id: string,
  profile: NonReadonly<Profile>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Profile>> => {
  return axios.put(`/de/api/v0/vitacurry/profiles/${id}/`, profile, options);
};

export const getV0VitacurryProfilesUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Profile> },
  ): Promise<AxiosResponse<Profile>> => {
    return v0VitacurryProfilesUpdate(id, arg, options);
  };
};
export const getV0VitacurryProfilesUpdateMutationKey = (id: string) =>
  [`/de/api/v0/vitacurry/profiles/${id}/`] as const;

export type V0VitacurryProfilesUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryProfilesUpdate>>
>;
export type V0VitacurryProfilesUpdateMutationError = AxiosError<unknown>;

export const useV0VitacurryProfilesUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0VitacurryProfilesUpdate>>,
      TError,
      Key,
      NonReadonly<Profile>,
      Awaited<ReturnType<typeof v0VitacurryProfilesUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurryProfilesUpdateMutationKey(id);
  const swrFn = getV0VitacurryProfilesUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryProfilesPartialUpdate = (
  id: string,
  patchedProfile: NonReadonly<PatchedProfile>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Profile>> => {
  return axios.patch(
    `/de/api/v0/vitacurry/profiles/${id}/`,
    patchedProfile,
    options,
  );
};

export const getV0VitacurryProfilesPartialUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PatchedProfile> },
  ): Promise<AxiosResponse<Profile>> => {
    return v0VitacurryProfilesPartialUpdate(id, arg, options);
  };
};
export const getV0VitacurryProfilesPartialUpdateMutationKey = (id: string) =>
  [`/de/api/v0/vitacurry/profiles/${id}/`] as const;

export type V0VitacurryProfilesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryProfilesPartialUpdate>>
>;
export type V0VitacurryProfilesPartialUpdateMutationError = AxiosError<unknown>;

export const useV0VitacurryProfilesPartialUpdate = <
  TError = AxiosError<unknown>,
>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0VitacurryProfilesPartialUpdate>>,
      TError,
      Key,
      NonReadonly<PatchedProfile>,
      Awaited<ReturnType<typeof v0VitacurryProfilesPartialUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurryProfilesPartialUpdateMutationKey(id);
  const swrFn = getV0VitacurryProfilesPartialUpdateMutationFetcher(
    id,
    axiosOptions,
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryProfilesDestroy = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/de/api/v0/vitacurry/profiles/${id}/`, options);
};

export const getV0VitacurryProfilesDestroyMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<AxiosResponse<void>> => {
    return v0VitacurryProfilesDestroy(id, options);
  };
};
export const getV0VitacurryProfilesDestroyMutationKey = (id: string) =>
  [`/de/api/v0/vitacurry/profiles/${id}/`] as const;

export type V0VitacurryProfilesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryProfilesDestroy>>
>;
export type V0VitacurryProfilesDestroyMutationError = AxiosError<unknown>;

export const useV0VitacurryProfilesDestroy = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0VitacurryProfilesDestroy>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof v0VitacurryProfilesDestroy>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurryProfilesDestroyMutationKey(id);
  const swrFn = getV0VitacurryProfilesDestroyMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurryProfilesFormSchemaRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Profile>> => {
  return axios.get(`/de/api/v0/vitacurry/profiles/form_schema/`, options);
};

export const getV0VitacurryProfilesFormSchemaRetrieveKey = () =>
  [`/de/api/v0/vitacurry/profiles/form_schema/`] as const;

export type V0VitacurryProfilesFormSchemaRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurryProfilesFormSchemaRetrieve>>
>;
export type V0VitacurryProfilesFormSchemaRetrieveQueryError =
  AxiosError<unknown>;

export const useV0VitacurryProfilesFormSchemaRetrieve = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0VitacurryProfilesFormSchemaRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0VitacurryProfilesFormSchemaRetrieveKey() : null));
  const swrFn = () => v0VitacurryProfilesFormSchemaRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurrySkillsList = (
  params?: V0VitacurrySkillsListParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PaginatedSkillList>> => {
  return axios.get(`/de/api/v0/vitacurry/skills/`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getV0VitacurrySkillsListKey = (
  params?: V0VitacurrySkillsListParams,
) => [`/de/api/v0/vitacurry/skills/`, ...(params ? [params] : [])] as const;

export type V0VitacurrySkillsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurrySkillsList>>
>;
export type V0VitacurrySkillsListQueryError = AxiosError<unknown>;

export const useV0VitacurrySkillsList = <TError = AxiosError<unknown>>(
  params?: V0VitacurrySkillsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0VitacurrySkillsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0VitacurrySkillsListKey(params) : null));
  const swrFn = () => v0VitacurrySkillsList(params, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurrySkillsCreate = (
  skill: NonReadonly<Skill>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Skill>> => {
  return axios.post(`/de/api/v0/vitacurry/skills/`, skill, options);
};

export const getV0VitacurrySkillsCreateMutationFetcher = (
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Skill> },
  ): Promise<AxiosResponse<Skill>> => {
    return v0VitacurrySkillsCreate(arg, options);
  };
};
export const getV0VitacurrySkillsCreateMutationKey = () =>
  [`/de/api/v0/vitacurry/skills/`] as const;

export type V0VitacurrySkillsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurrySkillsCreate>>
>;
export type V0VitacurrySkillsCreateMutationError = AxiosError<unknown>;

export const useV0VitacurrySkillsCreate = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof v0VitacurrySkillsCreate>>,
    TError,
    Key,
    NonReadonly<Skill>,
    Awaited<ReturnType<typeof v0VitacurrySkillsCreate>>
  > & { swrKey?: string };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getV0VitacurrySkillsCreateMutationKey();
  const swrFn = getV0VitacurrySkillsCreateMutationFetcher(axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurrySkillsRetrieve = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Skill>> => {
  return axios.get(`/de/api/v0/vitacurry/skills/${id}/`, options);
};

export const getV0VitacurrySkillsRetrieveKey = (id: string) =>
  [`/de/api/v0/vitacurry/skills/${id}/`] as const;

export type V0VitacurrySkillsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurrySkillsRetrieve>>
>;
export type V0VitacurrySkillsRetrieveQueryError = AxiosError<unknown>;

export const useV0VitacurrySkillsRetrieve = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof v0VitacurrySkillsRetrieve>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0VitacurrySkillsRetrieveKey(id) : null));
  const swrFn = () => v0VitacurrySkillsRetrieve(id, axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurrySkillsUpdate = (
  id: string,
  skill: NonReadonly<Skill>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Skill>> => {
  return axios.put(`/de/api/v0/vitacurry/skills/${id}/`, skill, options);
};

export const getV0VitacurrySkillsUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<Skill> },
  ): Promise<AxiosResponse<Skill>> => {
    return v0VitacurrySkillsUpdate(id, arg, options);
  };
};
export const getV0VitacurrySkillsUpdateMutationKey = (id: string) =>
  [`/de/api/v0/vitacurry/skills/${id}/`] as const;

export type V0VitacurrySkillsUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurrySkillsUpdate>>
>;
export type V0VitacurrySkillsUpdateMutationError = AxiosError<unknown>;

export const useV0VitacurrySkillsUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0VitacurrySkillsUpdate>>,
      TError,
      Key,
      NonReadonly<Skill>,
      Awaited<ReturnType<typeof v0VitacurrySkillsUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurrySkillsUpdateMutationKey(id);
  const swrFn = getV0VitacurrySkillsUpdateMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurrySkillsPartialUpdate = (
  id: string,
  patchedSkill: NonReadonly<PatchedSkill>,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Skill>> => {
  return axios.patch(
    `/de/api/v0/vitacurry/skills/${id}/`,
    patchedSkill,
    options,
  );
};

export const getV0VitacurrySkillsPartialUpdateMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (
    _: Key,
    { arg }: { arg: NonReadonly<PatchedSkill> },
  ): Promise<AxiosResponse<Skill>> => {
    return v0VitacurrySkillsPartialUpdate(id, arg, options);
  };
};
export const getV0VitacurrySkillsPartialUpdateMutationKey = (id: string) =>
  [`/de/api/v0/vitacurry/skills/${id}/`] as const;

export type V0VitacurrySkillsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurrySkillsPartialUpdate>>
>;
export type V0VitacurrySkillsPartialUpdateMutationError = AxiosError<unknown>;

export const useV0VitacurrySkillsPartialUpdate = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0VitacurrySkillsPartialUpdate>>,
      TError,
      Key,
      NonReadonly<PatchedSkill>,
      Awaited<ReturnType<typeof v0VitacurrySkillsPartialUpdate>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurrySkillsPartialUpdateMutationKey(id);
  const swrFn = getV0VitacurrySkillsPartialUpdateMutationFetcher(
    id,
    axiosOptions,
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurrySkillsDestroy = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/de/api/v0/vitacurry/skills/${id}/`, options);
};

export const getV0VitacurrySkillsDestroyMutationFetcher = (
  id: string,
  options?: AxiosRequestConfig,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<AxiosResponse<void>> => {
    return v0VitacurrySkillsDestroy(id, options);
  };
};
export const getV0VitacurrySkillsDestroyMutationKey = (id: string) =>
  [`/de/api/v0/vitacurry/skills/${id}/`] as const;

export type V0VitacurrySkillsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurrySkillsDestroy>>
>;
export type V0VitacurrySkillsDestroyMutationError = AxiosError<unknown>;

export const useV0VitacurrySkillsDestroy = <TError = AxiosError<unknown>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof v0VitacurrySkillsDestroy>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof v0VitacurrySkillsDestroy>>
    > & { swrKey?: string };
    axios?: AxiosRequestConfig;
  },
) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getV0VitacurrySkillsDestroyMutationKey(id);
  const swrFn = getV0VitacurrySkillsDestroyMutationFetcher(id, axiosOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};

export const v0VitacurrySkillsFormSchemaRetrieve = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<Skill>> => {
  return axios.get(`/de/api/v0/vitacurry/skills/form_schema/`, options);
};

export const getV0VitacurrySkillsFormSchemaRetrieveKey = () =>
  [`/de/api/v0/vitacurry/skills/form_schema/`] as const;

export type V0VitacurrySkillsFormSchemaRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v0VitacurrySkillsFormSchemaRetrieve>>
>;
export type V0VitacurrySkillsFormSchemaRetrieveQueryError = AxiosError<unknown>;

export const useV0VitacurrySkillsFormSchemaRetrieve = <
  TError = AxiosError<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof v0VitacurrySkillsFormSchemaRetrieve>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  axios?: AxiosRequestConfig;
}) => {
  const { swr: swrOptions, axios: axiosOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getV0VitacurrySkillsFormSchemaRetrieveKey() : null));
  const swrFn = () => v0VitacurrySkillsFormSchemaRetrieve(axiosOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};
