import { alpha, Components, Theme } from "@mui/material/styles";
import { gray } from "../themePrimitives";

export const componentsCustomizations: Components<Theme> = {
  MuiAlert: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: 10,
        color: theme.palette.text.primary,
        border: `1px solid ${alpha(gray[300], 0.9)}`,
        ...theme.applyStyles("dark", {
          border: `1px solid ${alpha(gray[700], 0.9)}`,
        }),
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: ({ theme }) => ({
        "& .MuiDialog-paper": {
          borderRadius: "10px",
          border: "1px solid",
          borderColor: theme.palette.divider,
        },
      }),
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: ({ theme }) => ({
        height: 8,
        borderRadius: 8,
        backgroundColor: gray[200],
        ...theme.applyStyles("dark", {
          backgroundColor: gray[800],
        }),
      }),
    },
  },
  MuiPaper: {
    defaultProps: {
      variant: "outlined" as "outlined",
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        margin: 0,
      },
    },
  },
  // MuiIconButton: {
  //   styleOverrides: {
  //     root: ({ theme }) => ({
  //       boxShadow: "none",
  //       borderRadius: theme.shape.borderRadius,
  //       textTransform: "none",
  //       fontWeight: theme.typography.fontWeightMedium,
  //       letterSpacing: 0,
  //       color: theme.palette.text.primary,
  //       border: "1px solid ",
  //       borderColor: gray[200],
  //       backgroundColor: alpha(gray[50], 0.3),
  //       "&:hover": {
  //         backgroundColor: gray[100],
  //         borderColor: gray[300],
  //       },
  //       "&:active": {
  //         backgroundColor: gray[200],
  //       },
  //       ...theme.applyStyles("dark", {
  //         backgroundColor: gray[800],
  //         borderColor: gray[700],
  //         "&:hover": {
  //           backgroundColor: gray[900],
  //           borderColor: gray[600],
  //         },
  //         "&:active": {
  //           backgroundColor: gray[900],
  //         },
  //       }),
  //     }),
  //   },
  // },
};
