import { ElementType, Suspense } from "react";

import LoadingSpinner from "../components/LoadingSpinner";

const Loadable = (Component: ElementType) => (props: any) => (
  <Suspense fallback={<LoadingSpinner />}>
    <Component {...props} />
  </Suspense>
);

export default Loadable;
