let appSettings: any;

export function getMediaPrefix(path: string) {
  if (path && path.startsWith("http")) {
    return path;
  }

  if (path && path.startsWith("data:image")) {
    return path;
  }
  return `${appSettings.api.root}${path}`;
}

const baseSettings = {};

if (process.env.NODE_ENV === "production") {
  appSettings = {
    ...baseSettings,
    api: {
      root: "https://api.lancer.express",
    },
  };
} else {
  appSettings = {
    ...baseSettings,
    api: {
      root: "http://localhost:8000",
    },
  };
}

export default appSettings;
