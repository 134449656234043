import { Navigation } from "@toolpad/core";
import { useTranslation } from "react-i18next";

import { Settings } from "@mui/icons-material";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import BlenderOutlinedIcon from "@mui/icons-material/BlenderOutlined";
import ContactsOutlined from "@mui/icons-material/ContactsOutlined";
/**
 * Get the main menu for the user
 */
const getMainMenu = (): Navigation => {
  const { t } = useTranslation();

  return [
    {
      segment: "contracts",
      title: t("Contracts"),
      icon: <AssignmentTurnedInOutlinedIcon />,
      pattern: "contracts{/:contractId}*",
    },
    {
      segment: "opportunities",
      title: t("Opportunities"),
      icon: <AddchartOutlinedIcon />,
      pattern: "opportunities{/:opportunityId}*",
    },
    {
      segment: "contacts",
      title: t("Contacts"),
      icon: <ContactsOutlined />,
      pattern: "contacts",
    },
    {
      segment: "vitacurry",
      title: t("Vita Curry"),
      icon: <BlenderOutlinedIcon />,
      pattern: "vitacurry",
    },
    {
      kind: "header",
      title: t("Account"),
    },
    {
      segment: "settings",
      title: t("Settings"),
      icon: <Settings />,
      pattern: "settings",
    },
  ];
};

export default getMainMenu;
