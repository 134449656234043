import { Grid2 as Grid, LinearProgress } from "@mui/material";

function LoadingSpinner() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid size={12}>
        <LinearProgress />
      </Grid>
    </Grid>
  );
}

export default LoadingSpinner;
