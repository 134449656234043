import { Alert, Backdrop, CircularProgress, useTheme } from "@mui/material";
import {
  AuthProvider,
  DashboardLayout,
  PageContainer,
  SignInPage,
  useLocalStorageState,
  type Router,
} from "@toolpad/core";
import { Session } from "@toolpad/core/AppProvider";
import { AppProvider } from "@toolpad/core/react-router-dom";
import axios from "axios";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "moment/locale/de";
import * as React from "react";
import { initReactI18next } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import "./App.css";
import { useLocalStorage } from "./hooks/useLocalStorage";
import translationDE from "./locales/de.json";
import logo from "./logo.png";
import logoDark from "./logoDark.png";
import getMainMenu from "./navigation/MainMenu";
import SidebarFooterAccount, {
  ToolbarAccountOverride,
} from "./navigation/SidebarFooterAccount";
import {
  useAuthLoginCreate,
  useV0UsersMeRetrieve,
} from "./orval/lancerExpressAPI";
import theme from "./theme";
import appSettings, { getMediaPrefix } from "./utils/apiSettings";

const getCurrentUser = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  }
  return undefined;
};

axios.defaults.baseURL = appSettings.api.root;
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.interceptors.request.use(
  (config: any) => {
    const currentUser = getCurrentUser();
    // Do something before request is sent
    if (currentUser) {
      config.headers["Authorization"] = `Bearer ${
        currentUser ? currentUser.access : ""
      }`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response: any) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 403) {
        localStorage.removeItem("user");
        window.location.reload();
      }
    }

    return Promise.reject(error);
  },
);

const resources = {
  de: { translation: translationDE },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    fallbackLng: "en",
    supportedLngs: ["de", "en"],
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

function CredentialsSignInPage(props: {
  branding: { logo: React.ReactNode; title: string };
}) {
  const theme = useTheme();
  const providers = [{ id: "credentials", name: "Email and Password" }];

  const [user, setUser] = useLocalStorage("user", null);

  const { trigger: login, error, isMutating } = useAuthLoginCreate();

  const signIn: (provider: AuthProvider, formData: FormData) => void = async (
    provider,
    formData,
  ) => {
    const { data } = await login(formData as any);
    setUser(data);
    window.location.reload();
  };

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ marginTop: theme.spacing(2) }}>
          {error.message}
        </Alert>
      )}
      <SignInPage
        signIn={signIn}
        providers={providers}
        sx={{
          minHeight: "80vh",
        }}
      />
    </>
  );
}

interface PublicAppProps {
  mode: string;
  branding: { logo: React.ReactNode; title: string };
}

function PublicApp(props: PublicAppProps) {
  const { mode, branding } = props;

  return (
    <AppProvider theme={theme} branding={branding}>
      <DashboardLayout hideNavigation hideFooter>
        <PageContainer>
          <CredentialsSignInPage branding={branding} />
        </PageContainer>
      </DashboardLayout>
    </AppProvider>
  );
}

interface PrivateAppProps {
  mode: string;
  branding: { logo: React.ReactNode; title: string };
}

function PrivateApp(props: PrivateAppProps) {
  const { mode, branding } = props;

  const navigate = useNavigate();
  const navigation = getMainMenu();

  // Setup states
  const [session, setSession] = React.useState<Session | null>(null);
  const [pathname, setPathname] = React.useState(
    window.location.pathname || "/",
  );

  // Get the current user
  const { data: userMeResponse, isLoading: isLoadingMeResponse } =
    useV0UsersMeRetrieve();

  React.useEffect(() => {
    if (userMeResponse) {
      const user = userMeResponse.data;
      setSession({
        user: {
          name: user?.name,
          email: user?.email,
          image: getMediaPrefix(user?.photo_thumb_50_50),
        },
      });
    }
  }, [userMeResponse]);

  const authentication = React.useMemo(() => {
    return {
      signIn: () => {},
      signOut: async () => {
        window.location.reload();
        localStorage.removeItem("user");
      },
    };
  }, []);

  const router = React.useMemo<Router>(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path: string) => {
        setPathname(String(path));
        navigate(path);
      },
    };
  }, [pathname]);

  if (isLoadingMeResponse) {
    return (
      <Backdrop
        open={true}
        children={<CircularProgress />}
        sx={(theme) => ({
          backgroundColor:
            mode === "light" ? theme.palette.background.paper : "",
          zIndex: theme.zIndex.drawer + 1,
        })}
      />
    );
  }

  return (
    <AppProvider
      authentication={authentication}
      navigation={navigation}
      session={session}
      router={router}
      theme={theme}
      branding={branding}
    >
      <DashboardLayout
        slots={{
          toolbarAccount: ToolbarAccountOverride,
          sidebarFooter: SidebarFooterAccount,
        }}
      >
        <Outlet />
      </DashboardLayout>
    </AppProvider>
  );
}

const setPwaHeaderColor = (color: string) => {
  const metaThemeColor = document.querySelector("meta[name=theme-color]");
  if (metaThemeColor) {
    metaThemeColor.setAttribute("content", color);
  }
};

export default function App() {
  const [mode] = useLocalStorageState("toolpad-mode", "light");
  const [user] = useLocalStorage("user", null);

  React.useEffect(() => {
    setPwaHeaderColor(theme[mode].palette.background.paper);
  }, []);

  React.useEffect(() => {
    setPwaHeaderColor(theme[mode].palette.background.paper);
  }, [mode]);

  const branding = {
    logo: (
      <img
        src={mode === "light" ? logoDark : logo}
        alt="Logo"
        style={{ height: 64 }}
      />
    ),
    title: "",
  };

  if (!user) {
    return <PublicApp mode={mode} branding={branding} />;
  }

  return <PrivateApp mode={mode} branding={branding} />;
}
